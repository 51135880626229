import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Step7 from '../dashboard/NewTestSteps/Step7';
import { useDispatch, useSelector } from 'react-redux';
import { getFormatedTest, getPayload } from '../../Raw/data';
import { EditTestPayload, resetState } from '../../store/slices/NewTestSlice';
import LoginBtn from './LoginBtn';
import { useUpdateScheduleTestMutation } from '../../store/apis/schedularlistApi';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
maxHeight:"90vh",
width:"90vw",
  overflow:"auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({src,data}) {
  
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true)
 dispatch(EditTestPayload(getFormatedTest(data)))
}

const dispatch = useDispatch();
  const handleClose = () => {
    
    dispatch(resetState())
    setOpen(false)
  
  
  }
  const payload = useSelector((state) => {
    return state.newTestPayload;
  });
  
  const [updateTest, results] = useUpdateScheduleTestMutation();





const onUpdateBtnClick = () =>{
   let newpay = getPayload(payload);
   delete newpay.instance_data;
   delete newpay.testname;
   delete newpay.dataname;
   delete newpay.devicename;
   delete newpay.deviceid;
   delete newpay.deviceport;
   delete newpay.device1name;
   delete newpay.device2name;
   delete newpay.device3name;
   delete newpay.lastruntime;
   delete newpay.lasterror;
   delete newpay.author;
   delete newpay.authorid;
   delete newpay.id_partition;
   delete newpay.comment;
    updateTest(newpay).then(()=>{

        dispatch(resetState())
    })
}

  return (
<>
         <img className="cursor-pointer" onClick={handleOpen} src={src} alt="edit icon" />
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Step7 payload={payload} />
        <div className="flex justify-start items-center  gap-x-8  mt-[20px]">
      <button
        className="h-[45px] px-10 border-[1px] rounded-3xl flex justify-center items-center border-primary text-primary"
        onClick={handleClose}
      >
        Cancel
      </button>
      <LoginBtn  
    //   loading={loading} name={activeStep == 6 ? "Save" : "Next"} 
    handleClick={onUpdateBtnClick}
      name="Update"
      />
      {/* <button
        onClick={activeStep == 6 ? onSaveBtnClick : onNextBtnClick}
        className="h-[45px] px-10 border-[1px] rounded-3xl flex justify-center items-center border-primary bg-primary hover:bg-opacity-90 disabled:opacity-25"
        // disabled={!passValidator()}
      >
        {activeStep == 6 ? "Save" : "Next"}
      </button> */}
    </div>
        </Box>
        
      </Modal>
      
    </>
  );
}