import React, { useEffect, useState } from "react";
import DashboardLayout from "../../pages/DashboardLayout";
import DashboardTabs from "./DashboardTabs";
import { Avatar, Menu, Tooltip } from "@mui/material";
import bellIcon from "../../assets/images/bell-icon.svg";
import Scheduler from "./Scheduler";
import {
  fetchListing,
  fetchResultListing,
} from "../../store/thunks/FetchListing";
import { useThunk } from "../../hooks/use-thunk";
import logo from "../../assets/images/logo.png";
import ResultPage from "./result/ResultPage";
import { MenuItem } from "@material-ui/core";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { signOutUser, signoutUser } from "../../store/slices/UserSlice";
import { token } from "../../Raw/data";
import { useFetchScheduleListQuery } from "../../store/apis/schedularlistApi";
import { getParameterList, getTestNameList } from "../../store/thunks/FetchTestItems";
import { useFetchRobotCountriesQuery, useFetchSimCountriesQuery } from "../../store/apis/fetchTestItemsApis";
import { loginUser } from "../../store/thunks/LoginUser";
import { logoutUser } from "../../store/thunks/LogoutUser";
import SimCardList from "./SimCards/SimCardList";
import DevicesMap from "./DevicesMap/DevicesMap";
import { useSelector } from "react-redux";

const MainPage = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(0);
  const [fetchList, isLoading, isError] = useThunk(fetchListing);
  const [logout , isLoaderm , isErrorm] = useThunk(logoutUser);
  const nav = useNavigate();
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();


  const {users} = useSelector((state)=>{
    return state;
  })



  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!users.isSigned) {
    nav("/");
  }
  const [fetchTestNames,LoadingTestNames, ErrorNames] = useThunk(getTestNameList);
  const {
    data: countrylist,
    error,
    isFetching:fetchCountries,
  } = useFetchRobotCountriesQuery();
  const [fetchParamterList, LoadingParameters, ErrorParameters] = useThunk(getParameterList);
  const { data: countrylistSim, error:errorcountry, isFetching } = useFetchSimCountriesQuery();
  useEffect(() => {
    fetchTestNames(users?.data?.user_data?.id_partition);
    fetchParamterList(users?.data?.user_data?.id_partition);
  }, []);
 
  function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  function stringAvatar(name) {
    return {
      
      children: `${name.split(' ')[0][0].toUpperCase()}${name.split(' ')[1][0].toUpperCase()}`,
    };
  }

  function handleSignOut(){

    dispatch(signOutUser())
  }
  

console.log(users)
  if(!users.isSigned)
  return<div></div>

  return (
    <DashboardLayout>
      <div className="px-10 py-4 w-full">
        {/* header */}
        <div className="flex items-center justify-between">
          <img
            src={logo}
            alt="Background"
            className="w-[100px] cursor-pointer"
          />
          {/* tabs */}

          <DashboardTabs value={value} setValue={setValue} />
          {/* user details */}
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-3">
              <Tooltip title={users.data.user_data.firstname} placement="left">
            
              <Avatar
             
              {...stringAvatar(users.data.user_data.firstname+" "+users.data.user_data.lastname)}
                className="cursor-pointer"
                id="basic-button"
                aria-controls={open ? "user-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                // sx={{ width: 34, height: 34 }}
              />
              </Tooltip>
              <Menu
                id="user-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleClose}>
                  <div
                    onClick={()=>handleSignOut()}
                  >
                    Signout
                  </div>
                </MenuItem>
              </Menu>
              {/* <p className="text-[14px] text-secondary font-[600]">User_Demo</p> */}
            </div>
            {/* <div className="p-2 relative">
              <img src={bellIcon} alt="bell-Icon" className="w-[34px]" />
              <div className="absolute top-0 right-2 p-[6px] rounded-full bg-[#E62E7B]"></div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="">
        {value == 0 && (
          <div>
            <Scheduler />
          </div>
        )}
        {value == 1 && (
          <div>
            <ResultPage />
          </div>
        )}
        {value == 2 && <div>
          
            <SimCardList/>
          </div>}
          {value==3 && <div className="h-[calc(100vh-81px)] flex justify-center items-center">
              <DevicesMap/>
            </div>

          }
      </div>
    </DashboardLayout>
  );
};

export default MainPage;
