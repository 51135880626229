import React from 'react'
import BasicTabs from '../../common/BasicTab';





const ResultTabs = ({dummyData}) => {
  
    const uniqueTestIds = new Set();

    dummyData.forEach(item => {
        uniqueTestIds.add(item.test_id);
      });

      const uniqueTestIdsArray = Array.from(uniqueTestIds);

     console.log(uniqueTestIdsArray);
  
    return (
    <div>

        <BasicTabs uniqueTestIds={uniqueTestIdsArray}/>


    </div>
  )
}

export default ResultTabs