import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { token } from "../../Raw/data";

const logoutUser = createAsyncThunk("user/logout", async () => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/admin/logout`,
    null,
    {
      headers: {
        "Content-Type": "application/json", // Adjust the content type based on your API requirements
        token: token(),
      },
    }
  );

  return response.data;
});


export {logoutUser};