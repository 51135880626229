import React, { useState } from 'react'
import {
    InputBase,
    InputAdornment,
    FormControlLabel,
    Checkbox,
  } from "@mui/material";
  
  import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import Password from '../../common/Password';
import LoginPage from '../../../pages/LoginPage';
import { token } from '../../../Raw/data';

  
const Reset = () => {
  
    const [password, setpassword] = useState();
    const [confirmpassword, setconfirmpassword] = useState();
 
 
    if(token())
  return <div></div>
  return (
  <LoginPage>
  <h2 className="text-3xl font-bold mb-2">Reset your password</h2>
  <p className="text-[16px] font-[500] mb-5 text-[#50514A]">
  Set a strong password with minimum eight characters, at least one uppercase and a special character
      </p>
      <form className="w-full">
      <div className="mb-6">
          <p className="text-[16px] font-[600]  mb-2">New Password</p>
       <Password password={password} setpassword={setpassword}/>
        </div>
        <div className="mb-6">
          <p className="text-[16px] font-[600]  mb-2">Confirm Password</p>
          <Password password={confirmpassword} setpassword={setconfirmpassword}/>
        </div>
        <div className="flex justify-between items-center">
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: "#AEC954",
                  "&.Mui-checked": {
                    color: "#AEC954",
                  },
                }}
              />
            }
            label="I am not a robot"
          />
       
        </div>
        <button
          type="submit"
          className="mt-4 color-secondary rounded-full font-[600] bg-primary w-full px-[20px] py-[8px]"
        >
        Send
        </button>
      </form>
  </LoginPage>
  )
}

export default Reset