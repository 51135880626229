import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { token } from "../../Raw/data";

const schedularlistApi = createApi({
  reducerPath: "schedulelist",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}`,
    prepareHeaders: (headers, { getState }) => {
      if (token()) {
        headers.set("token", token());
      }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),

  endpoints(builder) {
    return {
      fetchScheduleList: builder.query({
        query: (part_id) => {
          return {
            url: "/admin/getSchedularList",
            body: {partitionId:parseInt(part_id) 
              },
            method: "POST",
          };
        },
        providesTags: ["list"],
      }),

      createScheduleTest: builder.mutation({
        invalidatesTags: ["list"],
        query: (payload) => {
          return {
            url: "/admin/create-test",
            body: payload,
            method: "POST",
          };
        },
      }),

      updateScheduleTest: builder.mutation({
        invalidatesTags: ["list"],
        query: (payload) => {
          return {
            url: "/admin/update-test",
            body: payload,
            method: "POST",
          };
        },
      }),
      
      createInstanceTest: builder.mutation({
        invalidatesTags: ["list"],
        query: (payload) => {
          return {
            url: "/admin/create-instance",
            body: payload,
            method: "POST",
          };
        },
      }),

      updateInstanceTest: builder.mutation({
        invalidatesTags: ["list"],
        query: (payload) => {
          return {
            url: "/admin/update-instance",
            body: payload,
            method: "POST",
          };
        },
      }),



      deleteScheduleTest : builder.mutation({
        invalidatesTags: ["list"],
        query: (payload) => {
          return {
            url: "/admin/deleteTask",
            body: {id:payload},
            method: "POST",
          };
        },

      })
    };
    
  },
});

export const { useFetchScheduleListQuery, useCreateScheduleTestMutation , useDeleteScheduleTestMutation , useCreateInstanceTestMutation , useUpdateInstanceTestMutation , useUpdateScheduleTestMutation} =
  schedularlistApi;
export { schedularlistApi };
