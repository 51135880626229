import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import { Checkbox, Skeleton, Stack } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import { useUpdateDataColllectionMutation } from "../../../store/apis/fetchTestItemsApis";
import Swal from "sweetalert2";
import LoginBtn from "../../common/LoginBtn";
import { useSelector } from "react-redux";
import { StepGreenHeading, StepInputField, StepNavigationbtn } from "../NewTestSteps/Common";
import { useFetchResultErrorQuery } from "../../../store/apis/HeadCellsApi";
import { formattedDateFunction } from "../../../utils/helperdata/HelperData";
import { useDowloadFileDataMutation, useDowloadFileDataQuery } from "../../../store/apis/ResultFileApi";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //   width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Example 1", 159, 6.0, 24, 4.0),
  createData("Example 2", 237, 9.0, 37, 4.3),
  createData("Example 3", 262, 16.0, 24, 6.0),
  createData("Example 4", 305, 3.7, 67, 4.3),
  createData("Paramter 5", 356, 16.0, 49, 3.9),
];

export function BasicTable({ copydata,setcopydata}) {
  

  const [editindex, seteditindex] = useState();


  return (
    <TableContainer component={Paper} sx={{ maxHeight: "60vh" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
     
        <TableBody>
          {copydata&&Object.entries(copydata).map((row, index) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              onClick={() => seteditindex(index)}
            >
              <TableCell component="th" scope="row">
                {row[0]}
              </TableCell>
              <TableCell align="left">
                {row[1]}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default function FileModal({ data ,id , open ,handleClose , handleOpen2 , isFetching}) {


  const [copydata,setcopydata]=useState(data?data:{});
 
  React.useEffect(()=>{
    setcopydata(data)
  },[data])

  const [errorpayload,seterrorpayload] = useState({
    error_code:data?.col3,
    error_statement:data?.col2,
  })
  const keysToRetain = ["id", "testname"];
  React.useEffect(()=>{
    seterrorpayload({
      error_code:data?.col3,
      error_statement:data?.col2,
    })
  },[data])


  const {data:fetchErrorResult} = useFetchResultErrorQuery(errorpayload)
  const [downloadFile]  = useDowloadFileDataMutation();
  const [toggle,settoggle] = useState(true);
  const [loading ,setloading] = useState(false);
  const handleDownload = async (uri, name) =>{

    setloading(true);
   
    const downloadurl = `https://api-iqsim-testmanager.senarios.co/api/admin/download-result-file?path=${data.file_type_path}&file_name=${data.name}`;
    const response = await axios.get(downloadurl, {
      responseType: "arraybuffer", // Required to read response as binary data
      onDownloadProgress: (progressEvent) => {
        console.log(progressEvent);
        console.log(progressEvent.loaded);
        console.log(progressEvent.total);
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setloading(false)
        
        // setProgress(percentCompleted);
      },
    });

    console.log(response)

    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = downloadUrl;
    a.download = data.name; // Replace with the desired file name
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(downloadUrl);
    document.body.removeChild(a);

  }



  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >

        <Box sx={style}>
          <StepGreenHeading label="Download File" />
          <Stack
            direction="column"
            rowGap="24px"
            marginTop="20px"
            marginBottom="18px"
          >
             <StepInputField loading={isFetching} label="Name"  value={data?.name}   disabled={true}/>
            <StepInputField loading={isFetching} label="Comments"  value={data?.file_comment}    disabled={true}/> 
            <StepInputField loading={isFetching} label="Date Uploaded"  value={formattedDateFunction(data?.file_timestamp)}    disabled={true}/> 
            
            <StepInputField loading={isFetching} label="File Type"  value={data?.file_name}  disabled={true}/> 
            
            
          
          </Stack>

          <div className="flex justify-start items-center  gap-x-8">
      <button
        className="h-[45px] px-10 border-[1px] rounded-3xl flex justify-center items-center border-primary text-primary"
        onClick={handleClose}
      >
        Cancel
      </button>
      <LoginBtn  loading={loading} handleClick={handleDownload} name="Download" />
      {/* <button
        onClick={activeStep == 6 ? onSaveBtnClick : onNextBtnClick}
        className="h-[45px] px-10 border-[1px] rounded-3xl flex justify-center items-center border-primary bg-primary hover:bg-opacity-90 disabled:opacity-25"
        // disabled={!passValidator()}
      >
        {activeStep == 6 ? "Save" : "Next"}
      </button> */}
    </div>
        </Box>
      </Modal>
    </div>
  );
}
