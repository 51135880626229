import { createSlice } from "@reduxjs/toolkit";
import { fetchResultListing } from "../thunks/FetchListing";
const resultSlice = createSlice({
  name: "result",
  initialState: {
    resultListing: [],
    isLoading:false,
    tabs:[],
    selectedTab:0,
  },
  reducers: {
      updateSelectedTab(state,action){
        state.selectedTab = action.payload
        
    }


  },
  extraReducers(builder) {
    builder.addCase(fetchResultListing.pending, (state, action) => {
      state.isLoading = true;
      state.resultListing=[]
     
    });
    builder.addCase(fetchResultListing.fulfilled, (state, action) => {
        // console.log(action)
        state.resultListing = action.payload.data;
        const uniqueTestIds = new Set();

        action.payload.data.forEach(item => {
            uniqueTestIds.add(item.test_id);
          });
    
          const uniqueTestIdsArray = Array.from(uniqueTestIds);
    
       state.tabs=uniqueTestIdsArray;
      state.selectedTab=uniqueTestIdsArray[0]
      state.isLoading = false;
    });
    builder.addCase(fetchResultListing.rejected, (state, action) => {
      // state.isLoading = false;
      // state.error = action.error;
      console.log("error");
    });
  },
});

export const { fetchListing , updateSelectedTab } = resultSlice.actions;
export default resultSlice.reducer;
