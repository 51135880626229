import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import { useFetchRobotQuery } from "../../../store/apis/fetchRebotApi";
import { ClickAwayListener } from "@mui/material";
import { GiVirtualMarker } from "react-icons/gi";
import markericon from "../../../assets/images/marker3.png"

export default function DevicesMap() {


  const InfoWindow = ({ children, onClose, lat, lng }) => {
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
      <div
        style={{ position: "absolute", right:"50%" , transform:"translateX(50%)" , top: 5, padding: 15, background: "white" }}
        className="rounded-lg border border-gray-500"
        >
        <div>{children}</div>
     
      </div>
      </ClickAwayListener>
    );
  };


  const { data, isFetching } = useFetchRobotQuery();
  const [selectedRobot, setSelectedRobot] = useState(null);

  const defaultProps = {
    center: {
      lat: 48.86666666666667,
      lng: 2.333333,
    },
    zoom: 2,
  };

  const renderMarkers = (map, maps, robot) => {
    const { gps, capitalgps, name } = robot;

    const lat = gps ? gps.split(",")[0] : capitalgps.split(",")[0];
    const long = gps ? gps.split(",")[1] : capitalgps.split(",")[1];

    let marker = new maps.Marker({
      position: { lat: parseFloat(lat), lng: parseFloat(long) },
      map,
      title: `${name}`,
      icon: {
        url: markericon,
        scaledSize: new maps.Size(60, 60), // Adjust the size as needed
      },
    });

    marker.addListener("click", () => {
      setSelectedRobot(robot);
    });


    return marker;
  };

  const handleClickAway = () => {
    setSelectedRobot(null)
  };


  if (isFetching) return <div>Fetching Robots</div>;

  return (
    // Important! Always set the container height explicitly

    <div style={{ height: "80%", width: "90%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCINaKuLzoJyig0l-g62n8E-RxWUMPtwzE" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) =>
          data?.data?.map((robot) => {
            return renderMarkers(map, maps, robot);
          })
        }

      >

{selectedRobot && (
          <InfoWindow
            lat={parseFloat(selectedRobot.capitalgps.split(",")[0])}
            lng={parseFloat(selectedRobot.capitalgps.split(",")[1])}
            onClose={() => setSelectedRobot(null)}
          >
            <div className="w-[250px]">
              

              <table className="w-[100%] border-spacing-32 text-[12px] ">
              <tr>
                  <td className="font-[450]">Robot</td>
                  <td className="text-blue-700">{selectedRobot.regname} ({selectedRobot.status=="1"?"Ready":"Unreachable"}) </td>
                </tr>
                <tr>
                  <td className="font-[450]">Serial number</td>
                  <td>{selectedRobot.serial}</td>
                </tr>
                <tr>
                  <td className="font-[450]">Country</td>
                  <td>{selectedRobot.name}</td>
                </tr>
                <tr>
                  <td className="font-[450]">Country area</td>
                  <td></td>
                </tr>
              </table>
              
              {/* Add other information you want to display */}
            </div>
          </InfoWindow>
        )}


      </GoogleMapReact>
    </div>
  );
}



