import React, { useState } from "react";
import { InputBase, FormControlLabel, Checkbox } from "@mui/material";
import LoginPage from "../../../pages/LoginPage";
import { token } from "../../../Raw/data";

const Forget = () => {
  if(token())
  return <div></div>


  return (
    <LoginPage>
      <h2 className="text-3xl font-bold mb-2">Forgot password?</h2>
      <p className="text-[16px] font-[500] mb-5 text-[#50514A]">
        Please enter your account’s validated email address below. We will send
        you a link to reset your password.
      </p>
      <form className="w-full">
        <div className="mb-6">
          <p className="text-[16px] font-[600]  mb-2">Email Address</p>
          <InputBase
            type="text"
            className="w-full border font-[500] text-[14px]  rounded-full border-solid border-[#DADDD1] px-[20px] py-[3px] text-[#66685F] focus-within:border-primary focus-within:ring-[0px 2px 7px 0px rgba(128, 151, 51, 0.40)"
            placeholder="User_Demo@gmail.com"
          />
        </div>

        <div className="flex justify-between items-center">
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: "#AEC954",
                  "&.Mui-checked": {
                    color: "#AEC954",
                  },
                }}
              />
            }
            label="I am not a robot"
          />
        </div>
        <button
          type="submit"
          className="mt-4 color-secondary rounded-full font-[600] bg-primary w-full px-[20px] py-[8px]"
        >
          Send
        </button>
      </form>
    </LoginPage>
  );
};

export default Forget;
