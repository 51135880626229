import { TabContext, TabPanel } from "@mui/lab";
import { Box, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetState } from "../../store/slices/NewTestSlice";
import Swal from "sweetalert2";
import { updateActiveStep } from "../../store/slices/CompletedStepSlice";

const DashboardTabs = ({value,setValue}) => {
 
  const payload = useSelector((state)=>{
    return state.newTestPayload;
  })

  const dispatch = useDispatch();
  

  
  const handleChange = (event, newValue) => {
    if (payload.name || payload.testid)
    {
      Swal.fire({
        title:"Data lost ?",
        text: "Do you want to continue? All input data will be lost.",
        width:'398px',
        showCancelButton: true,
        confirmButtonText: "Proceed",
        confirmButtonColor: "#AEC954",
        padding:"24px",
        customClass: {
          cancelButton: 'h-[45px] px-10 !border-[1px] !border-solid !bg-transparent !rounded-3xl flex justify-center items-center !border-primary !text-primary',
          confirmButton: '!rounded-3xl px-10 h-[45px]',
          popup:"!text-[14px] !text-left",
          title:"!text-[20px] !p-0 !text-left !text-[#849C36]"
        },
        reverseButtons: true,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            dispatch(resetState())
            dispatch(updateActiveStep(0))
            setValue(newValue);
        }
      });

    }else{
      setValue(newValue);
    }
    
  };
  
  return (
    <div>
      <TabContext value={value.toString()}>
        <Box
          sx={{
            width: "100%",
            bgcolor: "#F4F4F4",
            borderRadius: "50px",
          }}
        >
          <Tabs
            TabIndicatorProps={{
              style: { display: "none" }, // Hide the indicator
            }}
            value={value}
            onChange={handleChange}
            centered
            
          >
            <Tab
            color='#50514A'
              sx={{
                textTransform: "capitalize",
                "&.Mui-selected": {
                  backgroundColor: "#AEC954",
                  color: "#0E1200",
                  borderRadius: "40px",
                  padding: "16px",
                },
                padding:"16px !important"
              }}
              label="Scheduler"
            />
            <Tab
            color='#50514A'
              sx={{
                textTransform: "capitalize",
                "&.Mui-selected": {
                  backgroundColor: "#AEC954",
                  color: "#0E1200",
                  borderRadius: "40px",
                  padding: "16px",
                },
                padding: "16px !important",
              }}
              label="Results"
            />
            <Tab
            color='#50514A'
            
              sx={{
                textTransform: "capitalize",
                "&.Mui-selected": {
                  backgroundColor: "#AEC954",
                  color: "#0E1200",
                  borderRadius: "40px",
                  padding: "16px",
                },
                padding: "16px !important",
              }}
              label="Sim cards"
            />
              <Tab
            color='#50514A'
            
              sx={{
                textTransform: "capitalize",
                "&.Mui-selected": {
                  backgroundColor: "#AEC954",
                  color: "#0E1200",
                  borderRadius: "40px",
                  padding: "16px",
                },
                padding: "16px !important",
              }}
              label="Devices Map"
            />
          </Tabs>
        </Box>
      </TabContext>
    </div>
  );
};

export default DashboardTabs;
