import React from "react";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";

function Step7({ payload }) {


  return (
    <>
      <Step1 payload={payload}/>
      <Step2 payload={payload}/>
      <Step3 payload={payload}/>
      <Step4 />
      {/* <Step5/> */}
      <Step6 payload={payload}/>
      
    </>
  );
}

export default Step7;
