import { Box, Step, StepButton, StepLabel, Stepper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { ActiveStepIcon, StepIcon } from "../../customIcons/customIcons";
import Step1 from "./NewTestSteps/Step1";
import Step2 from "./NewTestSteps/Step2";
import Step3 from "./NewTestSteps/Step3";
import Step4 from "./NewTestSteps/Step4";
import Step5 from "./NewTestSteps/Step5";
import Step6 from "./NewTestSteps/Step6";
import Step7 from "./NewTestSteps/Step7";
import { StepNavigationbtn } from "./NewTestSteps/Common";
import { useDispatch, useSelector } from "react-redux";
import { useThunk } from "../../hooks/use-thunk";
import { createTest } from "../../store/thunks/CreateTest";
import Swal from "sweetalert2";
import { createTests } from "../../service/services";
import { useNavigate } from "react-router-dom";
import { useCreateInstanceTestMutation, useCreateScheduleTestMutation } from "../../store/apis/schedularlistApi";
import { resetState } from "../../store/slices/NewTestSlice";
import { getInstancePayload, getPayload } from "../../Raw/data";
import { resetStepState, updateActiveStep } from "../../store/slices/CompletedStepSlice";
import { useUpdateDataColllectionMutation } from "../../store/apis/fetchTestItemsApis";
import { reject } from "lodash";
import StepsOverview from "./NewTestSteps/StepsOverview";

const useStyles = makeStyles(() => ({
  customActiveStepIcon: {
    "& svg.MuiSvgIcon-root.MuiStepIcon-root.Mui-active ,& svg.MuiSvgIcon-root.MuiStepIcon-root.Mui-completed":
      {
        color: "#AEC954", // Change color to green
        height: "36px",
        width: "36px",
      },
    "& span.MuiStepLabel-label.Mui-active ,& span.MuiStepLabel-label.Mui-completed":
      {
        color: "#AEC954", // Change color to green
      },
    "& .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel":
      {
        top: "18px",
      },
    "& .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.Mui-active span, & .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.Mui-completed span":
      {
        borderColor: "#AEC954",
      },
    "& .MuiButtonBase-root": {
      padding: "16px",
    },
  },
}));

function NewTest({ setNewTest }) {
  const classes = useStyles();
  const {activeStep, completedStep} = useSelector((state)=>{

    return state.testSteps;
  });
  
  
  
  const steps = [
    "Task Definition",
    "Test Parameters",
    "Robots Selection",
    "Sim Selection",
    "Execution Time",
    "Repetitive Task",
    "Summary",
  ];

  const handleStep = (step) => () => {
    dispatch(updateActiveStep(step))
  
  };

  const payload = useSelector((state) => {
    return state.newTestPayload;
  });

  const [startTest, isLoading, isError] = useThunk(createTest);
  const navigate = useNavigate();
  const [newTest, results] = useCreateScheduleTestMutation();
  const [creatInstance, res] = useCreateInstanceTestMutation();
  const dispatch = useDispatch();

  const nextBtnClick = () => {
    if (activeStep == 0) {
      if (payload.name && payload.testid && payload.duration) {
        dispatch(updateActiveStep(activeStep+1))
       
      } else {
        alert("Task name , Test name and Max test duration is required ");
      }
    } else if (activeStep == 1) {
      dispatch(updateActiveStep(activeStep+1))
    } else if (activeStep == 2) {
      if (!payload.RobotA && !payload.RobotB && !payload.RobotC) {
        alert("Please select atleast one Robot");
      } 
      else if(!payload.maxrobotdelay){
        alert("Max robot delay field is required");
      }
      else {
        dispatch(updateActiveStep(activeStep+1))
      }
    } else if (activeStep == 6) {
    } else {
      dispatch(updateActiveStep(activeStep+1))
    }
  };
  
  const [loading , setloading] = useState(false);
  const [updateCollection] = useUpdateDataColllectionMutation();
 const update = () => {

  return new Promise((resolve,reject)=>{
   
    let totalport =0;

    if(payload.Sim1.iccid)
    {
      totalport+=1;
    }
    if(payload.Sim2.iccid)
    {
      totalport+=1
    }
    if(payload.Sim3.iccid)
    {
      totalport+=1
    }
    let obj = {
      "id": "108",
      "Sim initialization": "",
      "Nb port": totalport,
      "ICCID Sim 1": payload.Sim1?.iccid?payload.Sim1.iccid.iccid:"",
      "ICCID Sim 2": payload.Sim2?.iccid?payload.Sim2.iccid.iccid:"",
      "ICCID Sim 3": payload.Sim3?.iccid?payload.Sim3.iccid.iccid:"",
  }

  let obj2={
    "id": "2",
    "ICCID sim1": payload.Sim1?.iccid?payload.Sim1.iccid.iccid:"",
"ICCID sim2": payload.Sim2?.iccid?payload.Sim2.iccid.iccid:"",
"ICCID sim3": payload.Sim3?.iccid?payload.Sim3.iccid.iccid:"",
"Number of port":"2",
"Initialisation":"FALSE",
"MSISDN Sim 1":"+33643620658",
"MSISDN Sim 2":"+33668849958",
"MSISDN Sim 3":"\\xA",
"Destination number":"",
  }

      




  updateCollection(obj2).then((result) => {
        if (result.data.status == true) {
          // Swal.fire({
          //   title: "Successfully updated parameters",
          //   icon: "success",
          //   width: "398px",
  
          //   confirmButtonText: "Ok",
          //   confirmButtonColor: "#AEC954",
          //   padding: "24px",
          //   customClass: {
          //     confirmButton: "!rounded-3xl px-10 h-[45px]",
          //     popup: "!text-[14px] !text-left",
          //     title: "!text-[20px] !p-0 !text-left !text-[#849C36]",
          //   },
          // }).then((result2) => {
          //   /* Read more about isConfirmed, isDenied below */
          //   if (result2.isConfirmed) {
          //   }
          // });
          resolve(true)
        } else {
          resolve(true)
          Swal.fire({
            title: "Error updating parameters",
            icon: "error",
  
            confirmButtonText: "OK",
            width: "398px",
            confirmButtonColor: "#AEC954",
            padding: "24px",
            customClass: {
              confirmButton: "!rounded-3xl px-10 h-[45px]",
              popup: "!text-[14px] !text-left",
              title: "!text-[20px] !p-0 !text-left !text-[#849C36]",
            },
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
          });
        }
      });



    
  })

  };


  const {users} = useSelector((state)=>{
    return state;
  })

  const saveBtnClick = () => {
  

     update().then(()=>{
      setloading(false);
   
   
      newTest(getPayload({partitionId:users?.data?.user_data?.id_partition,...payload  })).then((result) => {
        // dispatch(resetState())
     
        setloading(false)
        if (result.data.status == true) {
  
  
          Swal.fire({
            title: "Task created successfully.",
            icon: "success",
  
            confirmButtonText: "OK",
            width:'398px',
                
          
            confirmButtonColor: "#AEC954",
            padding:"24px",
            customClass: {
              confirmButton: '!rounded-3xl px-10 h-[45px]',
              popup:"!text-[14px] !text-left",
              title:"!text-[20px] !p-0 !text-center !text-[#849C36]"
            },
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              dispatch(resetState());
              dispatch(resetStepState())
              setNewTest(false);
            }
          });
        
          creatInstance({partitionId:users?.data?.user_data?.id_partition,...getInstancePayload(getPayload(payload),result.data.data.param)}).then(()=>{
  
  
           })
        
        } else {
          Swal.fire({
            title: "Error creating task",
            icon: "error",
            width:'398px',
                
          
            confirmButtonColor: "#AEC954",
            padding:"24px",
            customClass: {
              confirmButton: '!rounded-3xl px-10 h-[45px]',
              popup:"!text-[14px] !text-left",
              title:"!text-[20px] !p-0 !text-left !text-[#849C36]"
            },
            confirmButtonText: "OK",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
          });
        }
      });

     })
  
  };

  const passValidator = () => {
    if (payload.name) return false;
  };

  const checkIcon = (index, activeStep) => {
    if (index == activeStep) return ActiveStepIcon;
    if (index < activeStep) return null;
    return StepIcon;
  };

  const [stepCompleted, setStepCompleted] = useState(Array(6).fill(false));
  useEffect(() => {
    if (payload.name && payload.testid) {
      setStepCompleted((prev) => {
        return prev.map((obj, i) => {
          if (i == 0) {
            if (payload.name && payload.testid) return true;
            else return false;
          } else {
            if (i < activeStep) return true;

            return false;
          }
        });
      });
    }

    
    setStepCompleted((prev) => {
      return prev.map((obj, i) => {
        if (i < activeStep) return true;

        return;
      });
    });
  }, [payload, activeStep]);

  return (
    <div className="min-h-screen bg-[#F4F4F4]">
      <div className="px-10 py-4 ">
        <div className="text-blackbody font-medium text-sm flex items-center gap-x-4">
          <div>Your location is here</div>
          <div className="bg-[#D6D6D6] flex gap-x-4 items-center py-1 px-3 rounded-2xl">
            <span className="cursor-pointer" onClick={() => setNewTest(false)}>
              Scheduler
            </span>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.90403 1.02903C3.02607 0.90699 3.22393 0.90699 3.34597 1.02903L7.09597 4.77903C7.21801 4.90107 7.21801 5.09893 7.09597 5.22097L3.34597 8.97097C3.22393 9.09301 3.02607 9.09301 2.90403 8.97097C2.78199 8.84893 2.78199 8.65107 2.90403 8.52903L6.43306 5L2.90403 1.47097C2.78199 1.34893 2.78199 1.15107 2.90403 1.02903Z"
                  fill="#50514A"
                />
              </svg>
            </span>
            <span>Add new</span>
          </div>
        </div>
        <div className="pt-5">
          <Box sx={{ width: "100%" }}>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              className={classes.customActiveStepIcon}
            >
              {steps.map((label, index) => (
                <Step
                  key={label}
                  className="cursor-pointer"
                  onClick={stepCompleted[index] ? handleStep(index) : null}
                >
                  <StepLabel
                    StepIconComponent={
                      checkIcon(index, activeStep)

                      // index === activeStep
                      //   ? ActiveStepIcon
                      //   : index < activeStep
                      //   ? undefined
                      //   : StepIcon
                    }
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </div>

        <div className="mt-5 bg-white px-10 py-8 rounded-2xl">
          <div className="space-y-4 max-w-[916px]">
            {activeStep === 0 && <Step1 payload={payload} />}
            {activeStep === 1 && <Step2 payload={payload} />}
            {activeStep === 2 && <Step3 payload={payload} />}
            {activeStep === 3 && <Step4 />}
            {activeStep === 4 && <Step5 />}
            {activeStep === 5 && <Step6 payload={payload}  />}
            {activeStep === 6 && <StepsOverview  />}
            <StepNavigationbtn
              loading={loading}
              activeStep={activeStep}
              onNextBtnClick={nextBtnClick}
              onSaveBtnClick={saveBtnClick}
              passValidator={passValidator}
              setNewTest={setNewTest}
              
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewTest;
