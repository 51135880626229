import React, { useState } from 'react'
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import {
    InputBase,
    InputAdornment,
  } from "@mui/material";
const Password = ({password,setpassword}) => {
    
    const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  return (
    <InputBase
    type={showPassword ? "text" : "password"}
    className="w-full border font-[500] text-[14px]  rounded-full border-solid border-[#DADDD1] px-[20px] py-[3px] text-[#66685F] focus-within:border-primary focus-within:ring-[0px 2px 7px 0px rgba(128, 151, 51, 0.40)"
    placeholder="*******"
    value={password}
    onChange={(e)=>setpassword(e.target.value)}
    endAdornment={
      <InputAdornment position="end">
        <div className="cursor-pointer" onClick={handleTogglePassword}>
          {showPassword ? (
            <IoEyeOutline fontSize={20} />
          ) : (
            <IoEyeOffOutline fontSize={20} />
          )}
        </div>
      </InputAdornment>
    }
  />
  )
}

export default Password