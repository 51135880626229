import React from "react";
import {
  DisableIcon,
  PauseIcon,
  PlayIcon,
  ResetIcon,
} from "../../customIcons/customIcons";

const ScheduleButton = ({ children, handleClick, disabled, label }) => {

  console.log(label," ",disabled)

  return (
    <button
      disabled={disabled}
      onClick={handleClick}
      className={`flex items-center justify-center gap-2 text-[14px] px-4 cursor-pointer py-2  ${
        disabled ? "text-[#837a7a60]" : label=="Run"? "text-[#429E55]" : label=="Stop" ? "text-[#DE4343] " :  label=="Reset" ? "text-[#AEC954]" : "text-[#E79154]"
      } font-[600] border border-solid   ${
        disabled ? "border-[#837a7a60]" : label=="Run"? "border-[#429E55]" : label=="Stop" ? "border-[#DE4343] " :  label=="Reset" ? "border-[#AEC954]" : "border-[#E79154]"
      } rounded-3xl`}
    >
      {label == "Run" ? (
        <PauseIcon disabled={disabled} />
      ) : label == "Stop" ? (
        <PlayIcon disabled={disabled} />
      ) : label == "Reset" ? (
        <ResetIcon disabled={disabled} />
      ) : (
        <DisableIcon disabled={disabled} />
      )}
     
      {label}

      {children}
    </button>
  );
};

export default ScheduleButton;
