import { createSlice } from "@reduxjs/toolkit";
import { getDataCollection, getParameterList, getTestNameList } from "../thunks/FetchTestItems";

const fetchTestItemsSlice = createSlice({
  name: "fetchtestitems",
  initialState: {
    testnames:[],
    parameterslist:[],
  },
  reducers: {
    
    updateFetchItem(state, action) {
      state.datacollection = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getTestNameList.pending, (state, action) => {});
    builder.addCase(getTestNameList.fulfilled, (state, action) => {
      state.testnames = action.payload.data;
    });
    builder.addCase(getTestNameList.rejected, (state, action) => {});

    builder.addCase(getParameterList.pending, (state, action) => {});
    builder.addCase(getParameterList.fulfilled, (state, action) => {
      state.parameterslist = action.payload.data;
    });
    builder.addCase(getParameterList.rejected, (state, action) => {});

    builder.addCase(getDataCollection.pending, (state, action) => {});
    builder.addCase(getDataCollection.fulfilled, (state, action) => {
      state.datacollection = action.payload.data;
    });
    builder.addCase(getDataCollection.rejected, (state, action) => {});

  },
});

export default fetchTestItemsSlice.reducer;
export const {updateFetchItem} = fetchTestItemsSlice.actions;