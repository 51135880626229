import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  InputBase,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { DropdownIcon } from "../../../customIcons/customIcons";
import { withStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteTestPayload, resetState, updateTestPayload } from "../../../store/slices/NewTestSlice";
import LoginBtn from "../../common/LoginBtn";
import Swal from "sweetalert2";
import { updateActiveStep } from "../../../store/slices/CompletedStepSlice";

const CustomInput = withStyles({
  root: {
    borderRadius: "24px",
    border: "1px solid #D6D6D6",
    display: "flex",
    width: "179px",
    height: "45px",
    padding: "12px 24px",
    justifyContent: "space-between",
    alignItems: "center",
  },
})(InputBase);

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#AEC954",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export function StepNavigationbtn({
  onNextBtnClick,
  passValidator,
  onSaveBtnClick,
  activeStep,
  setNewTest,
  loading
}) {
  const payload = useSelector((state)=>{
    return state.newTestPayload;
  })


  const dispatch = useDispatch();
  const onCancelBtnClick = () =>{
    if (payload.name || payload.testid)
    {
      Swal.fire({
        title:"Data lost ?",
        text:"Do you want to continue? All input data will be lost.",
        width:'398px',
        showCancelButton: true,
        confirmButtonText: "Proceed",
        confirmButtonColor: "#AEC954",
        padding:"24px",
        customClass: {
          cancelButton: 'h-[45px] px-10 !border-[1px] !border-solid !bg-transparent !rounded-3xl flex justify-center items-center !border-primary !text-primary',
          confirmButton: '!rounded-3xl px-10 h-[45px]',
          popup:"!text-[14px] !text-left",
          title:"!text-[20px] !p-0 !text-left !text-[#849C36]"
        },
        reverseButtons: true,
      }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
          dispatch(resetState())
          dispatch(updateActiveStep(0))
        setNewTest(false)
      }
    });

    }
    else{
      setNewTest(false)
    }
  
  }
  return (
    <div className="flex justify-start items-center  gap-x-8">
      <button
        className="h-[45px] px-10 border-[1px] rounded-3xl flex justify-center items-center border-primary text-primary"
        onClick={onCancelBtnClick}
      >
        Cancel
      </button>
      <LoginBtn  loading={loading} name={activeStep == 6 ? "Save" : "Next"} handleClick={activeStep == 6 ? onSaveBtnClick : onNextBtnClick}/>
      {/* <button
        onClick={activeStep == 6 ? onSaveBtnClick : onNextBtnClick}
        className="h-[45px] px-10 border-[1px] rounded-3xl flex justify-center items-center border-primary bg-primary hover:bg-opacity-90 disabled:opacity-25"
        // disabled={!passValidator()}
      >
        {activeStep == 6 ? "Save" : "Next"}
      </button> */}
    </div>
  );
}

export function StepInputField({
  label,
  width,
  name,
  handleChange,
  defaultValue,
  value,
  type,
  error,
  disabled,
  placeholder,
  loading
}) {
  return (
    <>
      <div className="flex flex-col gap-y-2 ">
        <label className="text-base font-semibold" htmlFor="taskName">
          {label}
        </label>
        {
          loading? <Skeleton animation="wave"   className="!px-6 !h-[45px] !transform-none !outline-none !py-4 !border-[#D6D6D6] !border-[1px] !w-[390px]  !rounded-3xl" />
      :<input
      style={{ width: width }}
      name={name}
      defaultValue={defaultValue}
      id="taskName"
      placeholder={placeholder}
      className="px-6 h-[45px] outline-none py-4 border-[#D6D6D6] border-[1px] w-[390px]  rounded-3xl"
      type={type == "date" ? "date" : "text"}
      value={value}
      onChange={handleChange}
      disabled={disabled}
    />
        }
        
         {error && (
          <span className="text-red-500 text-[13px] relative bottom-[6px] left-[5px]">
            {error}
          </span>
        )}
      </div>
    </>
  );
}
const top100Films = [
  { label: 'The Shawshank Redemption', year: 1994 },
  { label: 'The Godfather', year: 1972 },
  { label: 'The Godfather: Part II', year: 1974 },
  { label: 'The Dark Knight', year: 2008 },
  { label: '12 Angry Men', year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: 'Pulp Fiction', year: 1994 },
  {
    label: 'The Lord of the Rings: The Return of the King',
    year: 2003,
  },
  { label: 'The Good, the Bad and the Ugly', year: 1966 },
  { label: 'Fight Club', year: 1999 },
  {
    label: 'The Lord of the Rings: The Fellowship of the Ring',
    year: 2001,
  },
  {
    label: 'Star Wars: Episode V - The Empire Strikes Back',
    year: 1980,
  },
  { label: 'Forrest Gump', year: 1994 },
  { label: 'Inception', year: 2010 },
  {
    label: 'The Lord of the Rings: The Two Towers',
    year: 2002,
  },
  { label: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { label: 'Goodfellas', year: 1990 },
  { label: 'The Matrix', year: 1999 },
  { label: 'Seven Samurai', year: 1954 },
  {
    label: 'Star Wars: Episode IV - A New Hope',
    year: 1977,
  },
  { label: 'City of God', year: 2002 },
  { label: 'Se7en', year: 1995 },
  { label: 'The Silence of the Lambs', year: 1991 },
  { label: "It's a Wonderful Life", year: 1946 },
  { label: 'Life Is Beautiful', year: 1997 },
  { label: 'The Usual Suspects', year: 1995 },
  { label: 'Léon: The Professional', year: 1994 },
  { label: 'Spirited Away', year: 2001 },
  { label: 'Saving Private Ryan', year: 1998 },
  { label: 'Once Upon a Time in the West', year: 1968 },
  { label: 'American History X', year: 1998 },
  { label: 'Interstellar', year: 2014 },
  { label: 'Casablanca', year: 1942 },
  { label: 'City Lights', year: 1931 },
  { label: 'Psycho', year: 1960 },
  { label: 'The Green Mile', year: 1999 },
  { label: 'The Intouchables', year: 2011 },
  { label: 'Modern Times', year: 1936 },
  { label: 'Raiders of the Lost Ark', year: 1981 },
  { label: 'Rear Window', year: 1954 },
  { label: 'The Pianist', year: 2002 },
  { label: 'The Departed', year: 2006 },
  { label: 'Terminator 2: Judgment Day', year: 1991 },
  { label: 'Back to the Future', year: 1985 },
  { label: 'Whiplash', year: 2014 },
  { label: 'Gladiator', year: 2000 },
  { label: 'Memento', year: 2000 },
  { label: 'The Prestige', year: 2006 },
  { label: 'The Lion King', year: 1994 },
  { label: 'Apocalypse Now', year: 1979 },
  { label: 'Alien', year: 1979 },
  { label: 'Sunset Boulevard', year: 1950 },
  {
    label: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
    year: 1964,
  },
  { label: 'The Great Dictator', year: 1940 },
  { label: 'Cinema Paradiso', year: 1988 },
  { label: 'The Lives of Others', year: 2006 },
  { label: 'Grave of the Fireflies', year: 1988 },
  { label: 'Paths of Glory', year: 1957 },
  { label: 'Django Unchained', year: 2012 },
  { label: 'The Shining', year: 1980 },
  { label: 'WALL·E', year: 2008 },
  { label: 'American Beauty', year: 1999 },
  { label: 'The Dark Knight Rises', year: 2012 },
  { label: 'Princess Mononoke', year: 1997 },
  { label: 'Aliens', year: 1986 },
  { label: 'Oldboy', year: 2003 },
  { label: 'Once Upon a Time in America', year: 1984 },
  { label: 'Witness for the Prosecution', year: 1957 },
  { label: 'Das Boot', year: 1981 },
  { label: 'Citizen Kane', year: 1941 },
  { label: 'North by Northwest', year: 1959 },
  { label: 'Vertigo', year: 1958 },
  {
    label: 'Star Wars: Episode VI - Return of the Jedi',
    year: 1983,
  },
  { label: 'Reservoir Dogs', year: 1992 },
  { label: 'Braveheart', year: 1995 },
  { label: 'M', year: 1931 },
  { label: 'Requiem for a Dream', year: 2000 },
  { label: 'Amélie', year: 2001 },
  { label: 'A Clockwork Orange', year: 1971 },
  { label: 'Like Stars on Earth', year: 2007 },
  { label: 'Taxi Driver', year: 1976 },
  { label: 'Lawrence of Arabia', year: 1962 },
  { label: 'Double Indemnity', year: 1944 },
  {
    label: 'Eternal Sunshine of the Spotless Mind',
    year: 2004,
  },
  { label: 'Amadeus', year: 1984 },
  { label: 'To Kill a Mockingbird', year: 1962 },
  { label: 'Toy Story 3', year: 2010 },
  { label: 'Logan', year: 2017 },
  { label: 'Full Metal Jacket', year: 1987 },
  { label: 'Dangal', year: 2016 },
  { label: 'The Sting', year: 1973 },
  { label: '2001: A Space Odyssey', year: 1968 },
  { label: "Singin' in the Rain", year: 1952 },
  { label: 'Toy Story', year: 1995 },
  { label: 'Bicycle Thieves', year: 1948 },
  { label: 'The Kid', year: 1921 },
  { label: 'Inglourious Basterds', year: 2009 },
  { label: 'Snatch', year: 2000 },
  { label: '3 Idiots', year: 2009 },
  { label: 'Monty Python and the Holy Grail', year: 1975 },
];
export function StepRealTimeInputField({
  label,
  width,
  name,
  handleChange,
  defaultValue,
  value,
}) {
  return (
    <>
      <div className="flex flex-col gap-y-2 ">
        <label className="text-base font-semibold" htmlFor="taskName">
          {label}
        </label>
        <input
          style={{ width: width }}
          name={name}
          defaultValue={defaultValue}
          id="taskName"
          placeholder="Sample text"
          className="px-6 h-[45px] outline-none py-4 border-[#D6D6D6] border-[1px] w-[390px]  rounded-3xl"
          type="text"
          value={value}
          onChange={handleChange}
        />
  
      </div>
    </>
  );
}

export function StepGreenHeading({ label }) {
  return (
    <Typography className="text-[17px] !font-[600] text-primary font-sans">
      {label}
    </Typography>
  );
}

export function StepDropDown({
  label,
  width,
  data,
  name,
  handleChange,
  defaultValue,
  value,
  loading,
  disabled,
}) {
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState(
    defaultValue ? defaultValue : value
  );

  useEffect(() => {
    setOptions(data);
    setSelectedValue(defaultValue ? defaultValue : value);
  }, [data]);

  const selectHandleChange = (e) => {
    setSelectedValue(e.target.value);
    handleChange(e);
  };

  return (
    <>
      <div className="flex flex-col gap-y-2 ">
        <label className="text-base font-semibold" htmlFor="test">
          {label}
        </label>
        <Box>
          <Select
            name={name}
            disabled={disabled}
            className=""
            input={
              <InputBase
                sx={{
                  borderRadius: "24px",
                  border: "1px solid #D6D6D6",
                  display: "flex",
                  width: width,
                  height: "45px",
                  padding: "12px 24px",
                  paddingRight:"9px",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}

                className="disabled:bg-[#FAFAFA]"
              />
            }
            IconComponent={() =>!disabled&&<DropdownIcon style={{
              position: "relative",
              right: "12px",
            
              
              pointerEvents: "none",
            }} />}
            value={selectedValue}
            onChange={selectHandleChange}
          >
            {loading ? (
              <MenuItem disabled>Loading...</MenuItem>
            ) : (
              options?.map((obj) => (
                <MenuItem key={obj.id} value={obj.id}>
                  {obj.name}
                </MenuItem>
              ))
            )}
          </Select>
        </Box>
      </div>
    </>
  );
}

export function StepTextArea({ label, value , disabled }) {
  return (
    <>
      <div className="flex flex-col gap-y-2">
        <label className="text-base font-semibold" htmlFor="taskName">
          {label}
        </label>
        <textarea
        disabled={disabled}
          className="px-6 min-h-[60px] w-full resize-none outline-none py-4 border-[#D6D6D6] border-[1px] rounded-3xl"
          type="text"
          name="taskName"
          id="taskName"
          value={value}
          spellCheck="false"
        />
      </div>
    </>
  );
}


export function AutoTextArea({ label, value , disabled , data , handleChange , renderOption }) {


return (
  <>
    <div className="flex flex-col gap-y-2 ">
    <label className="text-base font-semibold" htmlFor="taskName">
      {label}
    </label> 
   
    <Autocomplete
      sx={{
        display: "inline-block",
        "& input": {
          width: 200,
          bgcolor: "background.paper",
          color: (theme) =>
            theme.palette.getContrastText(theme.palette.background.paper),
        },
      }}
      getOptionLabel={(option) =>option.comment}
      id="custom-input-demo"
      getOptionDisabled={(option) =>
        option.comment === "...Loading"
      }
      value={value}
      options={data?data:[]}
      onChange={handleChange}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
       
          {option.comment}  
        </Box>
      )}
      renderInput={(params) => (
        <div ref={params.InputProps.ref}>
    
          {delete params.inputProps["className"]}
          <textarea
      disabled={disabled}
        className="px-6 min-h-[60px] w-full resize-none outline-none py-4 border-[#D6D6D6] border-[1px] rounded-3xl"
        type="text"
        name="taskName"
        id="taskName"
        {...params.inputProps}
        spellCheck="false"
      />
         
          
        </div>
      )}
    />
  </div>
 
  </>
);
}

export function Switcher({ switcher, handleChange , disabled }) {
  return (
    <Stack direction="row" spacing={3} alignItems="center" color="#50514A">
      <Typography>Dedicated</Typography>
      <IOSSwitch sx={{ m: 1 }}  disabled={disabled} checked={switcher} onChange={handleChange} />
      <Typography>Shared</Typography>
    </Stack>
  );
}

export function StepRadio({ label }) {
  return (
    <FormControlLabel
      sx={{ fontSize: "14px", fontWeight: 400, marginLeft: 0 }}
      control={
        <Checkbox
          defaultChecked
          sx={{
            color: "#D6D6D6",
          }}
        />
      }
      labelPlacement="start"
      label={label}
    />
  );
}

export function StepSelectTime({ label, handleChange ,hours, min ,disabled }) {
  const hoursArr = Array(24)
    .fill(0)
    .map((x, i) => {
      return { id: i, value: i < 10 ? "0" + i  : i  };
    });

  const minutesArr = Array(60)
    .fill(0)
    .map((x, i) => {
      return { id: i, value: i < 10 ? "0" + i : i };
    });

  return (
    <div className="flex gap-x-7">
      <div className="flex flex-col gap-y-2 ">
        <label className="text-base font-semibold" htmlFor="taskName">
          {label}
        </label>
        <Box sx={{ minWidth: 60 }}>
          <Select
            name="hours"
            input={<CustomInput />}
            IconComponent={() => <DropdownIcon />}
            onChange={handleChange}
            value={hours}
            disabled={disabled}
          >
            {hoursArr.map((obj) => {
              return <MenuItem value={obj.id}>{obj.value+" h"}</MenuItem>;
            })}
          </Select>
        </Box>
      </div>

      <div className="flex flex-col gap-y-2 justify-end">
        <Box sx={{ minWidth: 20 }}>
          <Select
            name="min"
            input={<CustomInput />}
            IconComponent={() => <DropdownIcon />}
            onChange={handleChange}
            value={min}
            disabled={disabled}
          >
            {minutesArr.map((obj) => {
              return <MenuItem value={obj.id}>{obj.value+" m"}</MenuItem>;
            })}
          </Select>
        </Box>
      </div>
    </div>
  );
}

export function StepWeekDays({ label }) {
  const {
    schmonday,
    schthuesday,
    schwednesday,
    schthursday,
    schfriday,
    schsaturday,
    schsunday,
  } = useSelector((state) => {
    return state.newTestPayload?.schedulepayload;
  });

  const {
    schedulepayload
  } = useSelector((state) => {
    return state.newTestPayload;
  });
  const dispatch = useDispatch();
  const handleChange = (e, day) => {
    
  
    if (day=="on") {

      dispatch(
        updateTestPayload({
          name: 'schedulepayload',
          value: {
            ...schedulepayload,
            [e.target.name]:"off"

          },
        })
      );
    } else {
      dispatch(
        updateTestPayload({
          name: 'schedulepayload',
          value: {
            ...schedulepayload,
            [e.target.name]:e.target.value

          },
        })
      );
    }
  };
  return (
    <>
      <Box>
        {" "}
        <label className="text-base font-semibold" htmlFor="taskName">
          {label}
        </label>
        <Stack direction="row" columnGap="30px" className="text-[#50514A]">
          <FormGroup className="!ml-0">
            <FormControlLabel
         className="!ml-0 flex !justify-between w-[250px]"
              labelPlacement="start"
              control={
                <Checkbox
                  checked={schmonday=="on"}
                  onChange={(e) => handleChange(e, schmonday)}
                  name="schmonday"
                />
              }
              label="Monday"
            />

            <FormControlLabel
            className="!ml-0 flex !justify-between w-[250px]"
              labelPlacement="start"
              control={
                <Checkbox
                  checked={schthuesday=="on"}
                  onChange={(e) => handleChange(e, schthuesday)}
                  name="schthuesday"
                />
              }
              label="Tuesday"
            />
            <FormControlLabel
            className="!ml-0 flex !justify-between w-[250px]"
              labelPlacement="start"
              control={
                <Checkbox
                  checked={schwednesday=="on"}
                  onChange={(e) => handleChange(e, schwednesday)}
                  name="schwednesday"
                />
              }
              label="Wednesday"
            />
          </FormGroup>

          <FormGroup>
            <FormControlLabel
              labelPlacement="start"
              className="!ml-0 flex !justify-between w-[250px]"
              control={
                <Checkbox
                  checked={schthursday=="on"}
                  onChange={(e) => handleChange(e, schthursday)}
                  name="schthursday"
                />
              }
              label="Thursday"
            />

            <FormControlLabel
            className="!ml-0 flex !justify-between w-[250px]"
              labelPlacement="start"
              control={
                <Checkbox
                  checked={schfriday=="on"}
                  onChange={(e) => handleChange(e, schfriday)}
                  name="schfriday"
                />
              }
              label="Friday"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
            className="!ml-0 flex !justify-between w-[250px]"
              labelPlacement="start"
              control={
                <Checkbox
                  checked={schsaturday=="on"}
                  onChange={(e) => handleChange(e, schsaturday)}
                  name="schsaturday"
                />
              }
              label="Saturday"
            />

            <FormControlLabel
            className="!ml-0 flex !justify-between w-[250px]"
              labelPlacement="start"
              control={
                <Checkbox
                  checked={schsunday=="on"}
                  onChange={(e) => handleChange(e, schsunday)}
                  name="schsunday"
                />
              }
              label="Sunday"
            />
          </FormGroup>
        </Stack>
      </Box>
    </>
  );
}


