import React, { useEffect, useState } from "react";
import { FaCircle } from "react-icons/fa";
import {
  AutoTextArea,
  StepDropDown,
  StepGreenHeading,
  StepInputField,
  StepRealTimeInputField,
  StepTextArea,
} from "./Common";
import {
  useFetchCardGroupListQuery,
  useFetchDataCollectionQuery,
  useFetchGroupedICCIDQuery,
  useFetchGsmOperatorQuery,
  useFetchICCIDQuery,
  useFetchSimCountriesQuery,
  useUpdateDataColllectionMutation,
} from "../../../store/apis/fetchTestItemsApis";

import CustomInputAutocomplete from "./AutoComplete";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTestPayload,
  updateTestPayload,
} from "../../../store/slices/NewTestSlice";
import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { updateFetchItem } from "../../../store/slices/FetchTestItemsSlice";
import LoginBtn from "../../common/LoginBtn";
import Swal from "sweetalert2";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

const SimICCID = ({ name }) => {
  const { data: countrylist, error, isFetching } = useFetchSimCountriesQuery();
  // const {
  //   data: grouplist,
  //   errorgroup,
  //   isFetchingroup,
  // } = useFetchCardGroupListQuery();
  const { [name]: namei  } = useSelector((state) => {
    return state.newTestPayload;
  });

  const { users  } = useSelector((state) => {
    return state;
  });

  const { datacollection } = useSelector((state) => {
    return state.newTestItems;
  });

  const [countryid, setcountryid] = useState(
    namei?.country ? namei?.country?.id : ""
  );
  const [carrierid, setcarrierid] = useState(
    namei?.carrier ? namei.carrier : ""
  );

  const [comment,setcomment] = useState("")
  const {
    data: gsmoperatorlist,
    error: errorgsm,
    isFetching: fetchgsm,
  } = useFetchGsmOperatorQuery(countryid);
  const {
    data: Iccidlist,
    error: IccidEror,
    isFetching: fetchIccid,
  } = useFetchICCIDQuery({ countrycode: countryid, provider: carrierid.name,comment:comment.comment=="Any"?"":comment.comment,partitionId: parseInt(users?.data?.user_data?.id_partition) });

  const [IccidFilter, setIccidFilter] = useState([]);

  // const {
  //   data: GroupedIccidlist,
  //   error: groupedIccidEror,
  //   isFetching: groupedfetchIccid,
  // } = useFetchGroupedICCIDQuery(namei?.group?.id);


  const dispatch = useDispatch();
  const OnCountryChange = (event, value) => {
    // Access the selected value and its ID
    if (value) {
      setcountryid(value.id);

      dispatch(
        updateTestPayload({
          name: name,
          value: { ...namei, country: value },
        })
      );
    }
  };

  const OnCarrierChange = (event, value) => {
    if (value) {
   
      setcarrierid(value);
      dispatch(
        updateTestPayload({
          name: name,
          value: { ...namei, carrier: value },
        })
      );
    }
  };

  const OnIccidChange = (event, value) => {
    dispatch(
      updateTestPayload({
        name: name,
        value: { ...namei, iccid: value },
      })
    );

    if (name === "Sim1") {
      if (datacollection?.hasOwnProperty("ICCID")) {
        dispatch(updateFetchItem({ ...datacollection, ICCID: value.iccid }));
      } else if (datacollection?.hasOwnProperty("ICCID sim port A")) {
        dispatch(
          updateFetchItem({
            ...datacollection,
            "ICCID sim port A": value.iccid,
          })
        );
      }
    }

    if (name === "Sim2") {
      if (datacollection?.hasOwnProperty("ICCID sim port B")) {
        dispatch(
          updateFetchItem({
            ...datacollection,
            "ICCID sim port B": value.iccid,
          })
        );
      }
    }

    if (name === "Sim3") {
      if (datacollection?.hasOwnProperty("ICCID sim port C")) {
        dispatch(
          updateFetchItem({
            ...datacollection,
            "ICCID sim port C": value.iccid,
          })
        );
      }
    }
  };

  const OnGroupChange = (event, value) => {
    // Access the selected value and its ID
    if (value) {
      dispatch(
        updateTestPayload({
          name: name,
          value: { ...namei, group: value },
        })
      );
    }
  };
  const [checked, setchecked] = useState(false);

  useEffect(() => {
    if (!fetchIccid ) {
    if (Iccidlist?.data) {
        setIccidFilter([...Iccidlist?.data]);
      } else {
        setIccidFilter([]);
      }
    }
  }, [Iccidlist]);


  const getDistinctArray = (inputArray, property) => {
    const uniqueSet = new Set(inputArray.map((item) => item[property]));
    return [...uniqueSet].map((uniqueValue) =>
      inputArray.find((item) => item[property] === uniqueValue)
    );
  };

  const OnCommentChange = (event,value)=>{
  
    if (value) {
  
     setcomment(value)
      dispatch(
        updateTestPayload({
          name: name,
          value: { ...namei, comment: value },
        })
      );
    }
   

  }


  return (
    <>
      <div className="flex flex-col gap-y-4">
        <div className="flex flex-col md:flex-row gap-3 justify-between lg:gap-x-[36px]">
          <CustomInputAutocomplete
            data={
              countrylist
                ? [{ id: 0, name: "Any" }, ...countrylist.data]
                : [{ id: 0, name: "Any" }]
            }
            value={namei?.country}
            handleChange={OnCountryChange}
            label="Country"
          />
          {/* <CountrySelect/> */}
          <CustomInputAutocomplete
            data={
              fetchgsm
                ? [{ id: 0, name: "...Loading" }]
                : gsmoperatorlist?.data
                ? getDistinctArray(
                    [{ id: 0, name: "Any" }, ...gsmoperatorlist.data],
                    "name"
                  )
                : [{ id: 0, name: "Any" }]
            }
            value={namei?.carrier}
            handleChange={OnCarrierChange}
            label="Carrier"
            loading={fetchgsm}
          />
        </div>
        {/* <CustomInputAutocomplete
          data={
            isFetchingroup
              ? [{ id: 0, name: "...Loading" }]
              : grouplist
              ? [
                  { id: 1, name: "None" },
                  { id: 0, name: "Any" },
                  ...grouplist.data,
                ]
              : [
                  { id: 0, name: "None" },
                  { id: 0, name: "Any" },
                ]
          }
          value={namei?.group}
          handleChange={OnGroupChange}
          label="Sim Group"
          loading={fetchgsm}
        /> */}
     <AutoTextArea label="Comment"    data={[{comment:"Any"},...IccidFilter.filter((y)=>y.comment).map((x) => {
        return {comment:x.comment };
      })]}  value={namei?.comment}
      handleChange={OnCommentChange}
     
       />
   
   

        <CustomInputAutocomplete
          data={ fetchIccid
            ? [{ id: 0, name: "...Loading" }]:IccidFilter.filter((y)=>y.iccid).map((x) => {
            return { ...x, name: x.iccid+` (${x.status})` };
          })}
          value={namei?.iccid}
          handleChange={OnIccidChange}
          label="ICCID"
          loading={fetchIccid}
        />
   
      </div>
    </>
  );
};

function Step4() {
  const { dataid, RobotA, RobotB, RobotC } = useSelector((state) => {
    return state.newTestPayload;
  });

  const { datacollection } = useSelector((state) => {
    return state.newTestItems;
  });
  const [updateCollection, results] = useUpdateDataColllectionMutation();
  const update = () => {
    updateCollection({ id: dataid, ...datacollection }).then((result) => {
      if (result.data.status == true) {
        Swal.fire({
          title: "Successfully updated parameters",
          icon: "success",
          width: "398px",

          confirmButtonText: "Ok",
          confirmButtonColor: "#AEC954",
          padding: "24px",
          customClass: {
            confirmButton: "!rounded-3xl px-10 h-[45px]",
            popup: "!text-[14px] !text-left",
            title: "!text-[20px] !p-0 !text-left !text-[#849C36]",
          },
        }).then((result2) => {
          /* Read more about isConfirmed, isDenied below */
          if (result2.isConfirmed) {
          }
        });
      } else {
        Swal.fire({
          title: "Error updating parameters",
          icon: "error",

          confirmButtonText: "OK",
          width: "398px",
          confirmButtonColor: "#AEC954",
          padding: "24px",
          customClass: {
            confirmButton: "!rounded-3xl px-10 h-[45px]",
            popup: "!text-[14px] !text-left",
            title: "!text-[20px] !p-0 !text-left !text-[#849C36]",
          },
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
        });
      }
    });
  };
  return (
    <>
      <StepGreenHeading label="Select Sim" />
      <Stack direction="column" rowGap={1}>
        {RobotA && (
          <Accordion>
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{ fontWeight: "800" }}>Sim A</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ pb: 5 }}>
              <SimICCID name="Sim1" />
            </AccordionDetails>
          </Accordion>
        )}

        {RobotB && (
          <Accordion>
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography sx={{ fontWeight: "800" }}>Sim B</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ pb: 5 }}>
              <SimICCID name="Sim2" />
            </AccordionDetails>
          </Accordion>
        )}
        {RobotC && (
          <Accordion>
            <AccordionSummary
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography sx={{ fontWeight: "800" }}>Sim C</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ pb: 5 }}>
              <SimICCID name="Sim3" />
            </AccordionDetails>
          </Accordion>
        )}
      </Stack>
      <LoginBtn
        name="Update ICCID's"
        handleClick={update}
        disabled={dataid == 0}
      />
    </>
  );
}

export default Step4;
