import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { token } from "../../Raw/data";

const fetchTestItemsApi = createApi({
  reducerPath: "testitems",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}`,
    prepareHeaders: (headers, { getState }) => {
      if (token()) {
        headers.set("token", token());
      }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),

  endpoints(builder) {
    return {
      fetchRobotCountries: builder.query({
        query: () => {
          return {
            url: "/admin/get-shared-robots-country",
            method: "GET",
          };
        },
        providesTags: ["robotcountries"],
      }),

      fetchRobotCities: builder.query({
        // providesTags: (result, error, user) => {
        //   console.log(result);
        //   const tags = result.data.data.map((city) => {
        //     return { type: "City", id: city.area_name };
        //   });

        //   return tags;
        // },

        query: (id) => {
          return {
            url: "/admin/get-shared-robots-country-area",
            body: {
              countrycode: id,
            },
            method: "POST",
          };
        },
        // providesTags:["robotcountries"],
      }),

      fetchSimCountries: builder.query({
        query: () => {
          return {
            url: "/admin/get-country-list",
            method: "GET",
          };
        },
      }),


      fetchDataCollection: builder.query({

        providesTags: (result, error, id) => {
      
          return [{ type: "parameter", id: id }];
        },
        query: (id) => {
          return {
            url: "/admin/get-data-collection",
            body: {
              id: id,
            },
            method: "POST",
          };
        },
      }),

      updateDataColllection: builder.mutation({

        invalidatesTags: (result, error, data) => {
          return [{ type: 'parameter', id: data.id }];
        },

        query: (data) => {
          return {
            url: "/admin/update-data-collection",
            body: data,
            method: "POST",
          };
        },
      }),


      fetchGsmOperator: builder.query({
        providesTags: (result, error, id) => {
         
          return [{ type: "gsm", id: id }];
        },

        query: (id) => {
          return {
            url: "/admin/get-gsm-operator-list",
            body: {
              countrycode: id,
            },
            method: "POST",
          };
        },
      }),

      fetchDedicatedRobot: builder.query({
        providesTags: ["delicateRobot"],

        query: (id) => {
          return {
            url: "/admin/get-delicated-robots",
            method: "GET",
          };
        },
      }),

      fetchICCID: builder.query({
        // providesTags: ["ICCID"],

        query: (id) => {
          return {
            url: "/admin/get-cards-list",
            body:id,
            method: "POST",
          };
        },
      }),

      
      fetchGroupedICCID: builder.query({
        // providesTags: ["ICCID"],

        query: (id) => {
          return {
            url: "/admin/get-card-group-card-list",
            body:{id:id},
            method: "POST",
          };
        },
      }),

      fetchCardGroupList: builder.query({
        // providesTags: (result, error, id) => {
        //   console.log(id);
        //   return [{ type: "gsm", id: id }];
        // },

        query: () => {
          return {
            url: "/admin/get-card-group-list",
            method: "GET",
          };
        },
      }),

      
    };
  },
});

export const {
  useFetchRobotCountriesQuery,
  useFetchRobotCitiesQuery,
  useFetchSimCountriesQuery,
  useFetchGsmOperatorQuery,
  useFetchDedicatedRobotQuery,
  useFetchICCIDQuery,
  useFetchDataCollectionQuery,
  useUpdateDataColllectionMutation,
  useFetchCardGroupListQuery,
  useFetchGroupedICCIDQuery
} = fetchTestItemsApi;
export { fetchTestItemsApi };
