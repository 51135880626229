import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { token } from "../../Raw/data";

const getTestNameList = createAsyncThunk(
  "fetchtestitems/getTestName",
  async (part_id) => {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/admin/get-test-list`,
      {
        partitionId: parseInt(part_id),
       
      },
       {
        headers: {
          "Content-Type": "application/json", // Adjust the content type based on your API requirements
          token: token(),
        },
      }
    );

    return response.data;
  }
);

const getParameterList = createAsyncThunk(
  "fetchtestitems/getParameterList",
  async (part_id) => {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/admin/testDataDescriptionList`,

      {
        partitionId: parseInt(part_id),
        addNone: 1,
        sort: 'name',
        dir: 'ASC',
      },
      {
        headers: {
          "Content-Type": "application/json", // Adjust the content type based on your API requirements
          token: token(),
        },
      }
    );

    return response.data;
  }
);

const getDataCollection = createAsyncThunk(

  "fetchtestitems/getDateCollection",
  async (id) => {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/admin/get-data-collection`,

      {
        id:id
      },
      {
        headers: {
          "Content-Type": "application/json", // Adjust the content type based on your API requirements
          token: token(),
        },
      }
    );

    return response.data;
  }
)

export { getTestNameList, getParameterList , getDataCollection};
