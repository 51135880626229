import { useState, useCallback } from "react";
import { useDispatch } from "react-redux";

export function useThunk(thunk) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();

  const runThunk = useCallback(
    (arg) => {
      setError(null)
      setIsLoading(true);
      setSuccess(false);
      dispatch(thunk(arg))
        .unwrap()
        .then((result) => {
          if (result.status === false) {
            setError("Passor");
          } else {
            setSuccess(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setError(err);
          // alert(JSON.stringify(err.message))
        })
        .finally(() => setIsLoading(false));
    },
    [dispatch, thunk]
  );

  return [runThunk, isLoading, error, success];
}
