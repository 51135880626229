import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

import { StepDropDown, StepGreenHeading } from "./Common";
import { useDispatch, useSelector } from "react-redux";
import { deleteTestPayload, updateTestPayload } from "../../../store/slices/NewTestSlice";
import BasicModal from "./Modal";
import { useFetchDataCollectionQuery } from "../../../store/apis/fetchTestItemsApis";
import { getDataCollection } from "../../../store/thunks/FetchTestItems";
import { useThunk } from "../../../hooks/use-thunk";
function Step2({ payload }) {
  const { parameterslist } = useSelector((state) => {
    return state.newTestItems;
  });

  const {data,isFetching}=useFetchDataCollectionQuery(payload.dataid);

  const [callDataCollection, isLoading, isError] = useThunk(getDataCollection);
  const dispatch = useDispatch();
  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateTestPayload({ name: name, value: value }));
 
    callDataCollection(e.target.value)
  };

  const handleEDBR = () =>{
    if(payload.taskflags==8)
    {
      dispatch(updateTestPayload({ name: 'taskflags', value: 0 }))

    }
    else{
      dispatch(updateTestPayload({ name: 'taskflags', value: 8 }))
    }
   
  } 

  const handleReset = () =>{
    if(payload.restart==1)
    {
      dispatch(updateTestPayload({ name: 'restart', value: 0 }))

    }
    else{
      dispatch(updateTestPayload({ name: 'restart', value: 1 }))
    }

  }

  function AlphabeticallySortArray(arrayOfObjects)
  {
    let sortedarray= [...arrayOfObjects]
  return sortedarray.sort((a, b) => {
    const nameA = a.name.toUpperCase(); // Ignore case while sorting
    const nameB = b.name.toUpperCase();
  
    if (nameA < nameB) {
      return -1; // a should come before b in the sorted order
    }
    if (nameA > nameB) {
      return 1; // a should come after b in the sorted order
    }
    return 0; // names are equal, no change in order
  });

}

  return (
    <>
      <StepGreenHeading label="Test Parameter" />
      <div className="flex flex-col md:flex-row gap-3 lg:gap-x-[136px]">
        <div className="flex flex-col gap-y-2 flex-1">
          <div className="flex gap-x-[16px] items-end">
            <StepDropDown
              label="Parameters"
              width="390px"
              name="dataid"
              handleChange={handleChange}
              value={payload.dataid}
              data={[{id:0,name:"None"},...AlphabeticallySortArray(parameterslist)]}
            />
          
            <BasicModal  isFetching={isFetching}  data={data?data.status==true?data.data:{}:{}} id={payload.dataid}/>
          </div>
        </div>
      </div>

      <div className="flex  gap-x-6">
        <FormControlLabel
          sx={{ fontSize: "14px", fontWeight: 400, marginLeft: 0 }}
          control={
            <Checkbox
              checked={payload.taskflags==8}
              onChange={handleEDBR}
              sx={{
                color: "#D6D6D6",
              }}
            />
          }
          labelPlacement="start"
          label="Edit Data before run"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={payload.restart==1}
              onChange={handleReset}
              sx={{
                color: "#D6D6D6",
              }}
            />
          }
          labelPlacement="start"
          label="Reset Parameters index"
        />
      </div>
    </>
  );
}

export default Step2;
