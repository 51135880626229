import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { token } from "../../Raw/data";

const headCellsApi = createApi({
  reducerPath: "headcell",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}`,
    prepareHeaders: (headers, { getState }) => {
      if (token()) {
        headers.set("token", token());
      }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),

  endpoints(builder) {
    return {
      fetchHeadCell: builder.query({
        query: (obj) => {
          return {
            url: "/admin/get-column-order",
            body: obj,
            method: "POST",
          };
        },
      }),


      modifyHeadCell: builder.mutation({
        query: (obj) => {
          return {
            url: "/admin/modify-Column-Order",
            body: obj,
            method: "POST",
          };
        },
      }),

      fetchCellsNames: builder.query({
        query: (obj) => {
          return {
            url: "/admin/get-column-name",
            body: obj,
            method: "POST",
          };
        },
      }),


      fetchResultError: builder.query({
        query: (obj) => {
          return {
            url: "/admin/get-error-response",
            body: obj,
            method: "POST",
          };
        },
      }),


    };
  },
});

export const { useFetchHeadCellQuery , useModifyHeadCellMutation , useFetchCellsNamesQuery , useFetchResultErrorQuery} = headCellsApi;
export { headCellsApi };
