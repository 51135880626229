import { createSlice } from "@reduxjs/toolkit";
import {fetchListing } from "../thunks/FetchListing";

const schedularSlice = createSlice({
  name: "schedular",
  initialState: {
    listing: [],
    isLoading:false,
  },
  reducers: {
    fetchListings(state, action) {
      // state.isSigned = true;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchListing.pending, (state, action) => {
      state.isLoading = true;
    
    });
    builder.addCase(fetchListing.fulfilled, (state, action) => {
      state.isLoading = false;
      // console.log(action.payload.data);
      state.listing = action.payload.data?.map((item) => ({
        id: item.id,
        INSTANCE: "1",
        NAME: item.name,
        TEST: item.testname,
        DESCRIPTION: item.comment,
        "DASHBOARD REFERENCE":
          typeof item.dashboardreference === "string"
            ? item.dashboardreference
            : null,
        "DATA COLLECTION": item.dataname,
        TRIGGER: "", // You might need to adjust this based on your data
        STATUS: "TODO", // You may need to determine how to derive the status
        AUTHOR: item.author,
        VERBOS: item.verbose === "0" ? "None" : "Medium",
        "TASK ID": item.id,
        "TASK TYPE": item.tasktype == 2 ? "TEST" : null,
        "#A ROBOT": item.device1name,
        "#A PORT": item.device1port,
        "#A AREA": item.device1area === "None" ? null : item.device1area,
        "#A COUNTRY": item.device1country == 0 ? null : item.device1country,
        "#B ROBOT": item.device2name,
        "#B PORT": item.device2port,
        "#B AREA": item.device2area === "None" ? null : item.device2area,
        "#B COUNTRY": item.device2country == 0 ? null : item.device2country,
        "#C ROBOT": item.device3name,
        "#C PORT": item.device3port,
        "#C AREA": item.device3area === "None" ? null : item.device3area,
        "#C COUNTRY": item.device3country == 0 ? null : item.device3country,
        "LAST RUN": item.lastruntime,
        matchedInstances: item.instance_data,
        ACTIONS: "", // You may need to determine how to derive the actions
      }));
    });
    builder.addCase(fetchListing.rejected, (state, action) => {
      // state.isLoading = false;
      // state.error = action.error;
      console.log("error");
    });

  },
});
export const { fetchListings } = schedularSlice.actions;

export default schedularSlice.reducer;
