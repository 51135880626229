export const token = () => {
  return localStorage.getItem("iq_userToken");
};

export const sample = {
  name: "New Sched test1",
  testid: 1033,
  dataid: 104,
  triggertype: 1,
  trigger_repeat: 0,
  repeat_test: 1,
  duration: 3600,
  maxrobotdelay: 300,
  restart: 0,
  triggervalue: 1701126000,
  master_task_id: 0,
  task_user_id: 146,
  triggerstopvalue: 1701126000,
  taskflags: 0,
  portstatusflag: 0,
  tasktype: 2,
  verbose: 0,
  device1id: 9477,
  device1port: 0,
  device2id: 0,
  device2port: 0,
  device3id: 0,
  device3port: 0,
  dashboardreference: "",
  device1country: 0,
  device1area: "None",
  device1ratmode: 255,
  device2country: 0,
  device2area: "None",
  device2ratmode: 255,
  device3country: 0,
  device3area: "None",
  device3ratmode: 255,
  restarttype: 0,
};

export const setratmode = (arr) => {
  if (arr.includes("Any")) {
    return 255;
  } else {
    let total = 0;

    if (arr.includes("3G")) {
      total += 2;
    }
    if (arr.includes("4G")) {
      total += 4;
    }
    if (arr.includes("5G")) {
      total += 8;
    }

    return total;
  }
};

export const getratmode = (digit) => {
  if (digit == 255) return ["Any", "5G", "4G", "3G"];
  if (digit == 2) return ["3G"];
  if (digit == 4) return ["4G"];
  if (digit == 8) return ["5G"];

  if (digit == 6) {
    return ["3G", "4G"];
  }
  if (digit == 10) {
    return ["3G", "5G"];
  }
  if (digit == 12) {
    return ["4G", "5G"];
  }
  if (digit == 14) return ["3G", "4G", "5G"];
};
function formatNumberWithLeadingZero(number) {
  // Convert the number to a string
  let formattedNumber = number.toString();

  // Add a leading zero if the number has only one digit
  if (formattedNumber.length === 1) {
    formattedNumber = "0" + formattedNumber;
  }

  return formattedNumber;
}
export const getPayload = (payload) => {
  let x = { ...payload };
  if (x?.RobotA?.resource == "shared") {
    x.device1country = payload.RobotA.device1country;
    x.device1area = payload.RobotA.device1area;
    x.device1ratmode = payload.RobotA.device1ratmode;
  }
  if (x?.RobotA?.resource == "dedicated") {
    x.device1port = payload.RobotA.device1port;
    x.device1id = payload.RobotA.device1id;
  }
  if (x?.RobotB?.resource == "shared") {
    x.device2country = payload.RobotB.device2country;
    x.device2area = payload.RobotB.device2area;
    x.device2ratmode = payload.RobotB.device2ratmode;
  }
  if (x?.RobotB?.resource == "dedicated") {
    x.device2port = payload.RobotB.device2port;
    x.device2id = payload.RobotB.device2id;
  }
  if (x?.RobotC?.resource == "shared") {
    x.device3country = payload.RobotC.device3country;
    x.device3area = payload.RobotC.device3area;
    x.device3ratmode = payload.RobotC.device3ratmode;
  }
  if (x?.RobotC?.resource == "dedicated") {
    x.device3port = payload.RobotC.device3port;
    x.device3id = payload.RobotC.device3id;
  }

  delete x.RobotB;
  delete x.RobotC;
  delete x.RobotA;

  if (payload.triggertype == 7) {
    let y = 0;
    if (payload.schedulepayload.schmonday == "on") {
      y += 1;
    }
    if (payload.schedulepayload.schthuesday == "on") {
      y += 2;
    }
    if (payload.schedulepayload.schwednesday == "on") {
      y += 4;
    }
    if (payload.schedulepayload.schthursday == "on") {
      y += 8;
    }
    if (payload.schedulepayload.schfriday == "on") {
      y += 10;
    }
    if (payload.schedulepayload.schsaturday == "on") {
      y += 20;
    }
    if (payload.schedulepayload.schsunday == "on") {
      y += 40;
    }

    x.trigger_repeat = y;
  }

  if (x.schedulepayload) {
    x.triggervalue =
      x.schedulepayload.startdate +
      " " +
      formatNumberWithLeadingZero(x.schedulepayload.hours) +
      ":" +
      formatNumberWithLeadingZero(x.schedulepayload.min) +
      ":00";

    if (x.schedulepayload.triggerstop && x.schedulepayload.triggerstopchecked) {
      x.triggerstopvalue =
        x.schedulepayload.stopdate +
        " " +
        formatNumberWithLeadingZero(x.schedulepayload.s_hours) +
        ":" +
        formatNumberWithLeadingZero(x.schedulepayload.s_min) +
        ":00";
    }
  }
  delete x.schedulepayload;
  delete x.resource;
  delete x.schedulepayload;
  delete x.startdate;
  delete x.hours;
  delete x.min;
  delete x["Sim1"];
  delete x["Sim2"];
  delete x["Sim3"];

  x.task_user_id = 0;
  x.tasktype = 2;

  x.portstatusflag = 0;
  x.restarttype = 0;
  x.master_task_id = 0;
  x.repeat_test = 1;
  x.dashboardreference = "";

  x.status = 0;

  delete x.robotname;
  delete x.country;
  delete x.carrier;
  delete x["rb_trigger"];
  delete x["editdatabrun"];

  return x;
};

export const getInstancePayload = (payload, id) => {
  let x = { ...payload };

  const myObject = {
    id: id,
    task_status: "0",
    isi_number: "1",
    trigger_value: payload.triggervalue,
    task_flags: payload.task_flags,
    task_user_id: "0",
    device1_id: payload.device1id,
    device1_port: payload.device1port,
    device2_id: payload.device2id,
    device2_port: payload.device2port,
    device3_id: payload.device3id,
    device3_port: payload.device3port,
    shared_robot1_id: payload.device1id,
    shared_robot1_port: payload.device1port,
    shared_robot1_country: payload.device1country,
    shared_robot1_area: payload.device1area,
    shared_robot1_rat: payload.device1ratmode,
    shared_robot2_id: payload.device2id,
    shared_robot2_port: payload.device2port,
    shared_robot2_country: payload.device2country,
    shared_robot2_area: payload.device2area,
    shared_robot2_rat: payload.device2ratmode,
    shared_robot3_id: payload.device3id,
    shared_robot3_port: payload.device3port,
    shared_robot3_country: payload.device3country,
    shared_robot3_area: payload.device3area,
    shared_robot3_rat: payload.device3ratmode,
    own_robot1_id: payload.device1id,
    own_robot1_port: payload.device1port,
    own_robot1_country: payload.device1country,
    own_robot1_area: payload.device1area,
    own_robot1_rat: payload.device1ratmode,
    own_robot2_id: payload.device2id,
    own_robot2_port: payload.device2port,
    own_robot2_country: payload.device2country,
    own_robot2_area: payload.device2area,
    own_robot2_rat: payload.device2ratmode,
    own_robot3_id: payload.device3id,
    own_robot3_port: payload.device3port,
    own_robot3_country: payload.device3country,
    own_robot3_area: payload.device3area,
    own_robot3_rat: payload.device3ratmode,
  };
  return myObject;
};

export const getInstanceStatus = (index) => {
  const status_array = [
    "Idle",
    "Running",
    "Waiting",
    "Done",
    "Disable",
    "End By User",
    "Force Run",
    "Timeout",
    "DB Error",
    "Robot Busy",
    "Syntax Error",
    "Database Error",
    "Execution Error",
    "Stop Test Request",
    "Pause Test Request",
    "Paused",
    "Resume Request",
    "No Price",
    "No Credit",
    "No Robot",
  ];

  return status_array[index] ? status_array[index] : "unknown";
};

export const getFormatedTest = (data) => {
  let formated = { ...data };

  formated.RobotA = {
    device1area: data.device1area,
    device1port: data.device1port,
    device1country: data.device1country,
    device1ratmode: data.device1ratmode,
    device1id: data.device1id,
    resource: data.device1country ? "dedicated" : "shared",
  };

  return formated;
};

export const getTriggerTypeFromNumber = (x) => {
  switch (x) {
    case "0":
      return "Now";
      break;
    case "1":
      return "Start on";
      break;
    case "6":
      return "Each day";
      break;
    case "7":
      return "Each Week";
      break;
    case "9":
      return "Each minute"
      break;
    }
};
