import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { token } from "../../Raw/data";

const resultFileApi = createApi({
  reducerPath: "file",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}`,
    prepareHeaders: (headers, { getState }) => {
      if (token()) {
        headers.set("token", token());
      }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),

  endpoints(builder) {
    return {
      fetchFileData: builder.query({
        query: (obj) => {
          return {
            url: "/admin/get-itm-file-list",
            body: obj,
            method: "POST",
          };
        },
      }),



      dowloadFileData: builder.mutation({
        query: (obj) => {
          return {
            url: "/admin/download-result-file",
            body: obj,
            method: "GET",
          };
        },
      }),
    };
  },
});

export const {useFetchFileDataQuery, useDowloadFileDataMutation} = resultFileApi;
export {resultFileApi}