import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { Box } from "@mui/material";



export default function CustomInputAutocomplete({data,label,handleChange,value,loading,renderOption}) {

  
  return (
    <div className="flex flex-col gap-y-2 w-[350px]">
      <label className="text-base font-semibold" htmlFor="taskName">
        {label}
      </label> 
     
      <Autocomplete
        sx={{
          display: "inline-block",
          "& input": {
            width: 200,
            bgcolor: "background.paper",
            color: (theme) =>
              theme.palette.getContrastText(theme.palette.background.paper),
          },
        }}
        getOptionLabel={(option) =>option.name}
        id="custom-input-demo"
        getOptionDisabled={(option) =>
          option.name === "...Loading"
        }
        value={value}
        options={data?data:[]}
        onChange={handleChange}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
         
            {renderOption?option[renderOption]:option.name}  
          </Box>
        )}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
      
            {delete params.inputProps["className"]}
           
            <input
              className="!px-6 !h-[45px] !outline-none !py-4 !border-[#D6D6D6] !border-[1px] !w-[352px]  !rounded-3xl"
              type="text"
              {...params.inputProps}
            />
            
          </div>
        )}
      />
    </div>
  );
}
