import React from "react";
import { LoadingButton } from "@mui/lab";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  customButton: {
    "&.MuiLoadingButton-loading": {
      backgroundColor: "#aec954a1  !important", // Override the background color for the loading state
    },
    color: "white !important",
    "@media (max-width: 780px)": {
      height: "45px",
      justifyContent: "center",
    },
  },
});
const MuiLoadingButton = ({ label, loading, type, isDisabled }) => {
  const classes = useStyles();
  return (
    <LoadingButton
      type={type}
      className={classes.customButton}
      //  loadingClassName={classes.loadingButton}

      sx={{
        color: "#AEC954",
        width: "100%",
        height: "45px",
        padding: "8px 20px",
        justifyContent: "text-center",
        backgroundColor: isDisabled ? "#aec954a1" : "#AEC954",
        borderColor: "transparent",
        borderRadius: "25px",
        "&:hover": {
          backgroundColor: "#aec954a1",
          borderColor: "transparent",
        },
      }}
      size="medium"
      // onClick={handleClick}
      // endIcon={<IoChevronForwardOutline />}
      loading={loading}
      disabled={isDisabled}
      loadingPosition=""
      variant="outlined"
    >
      {!loading && (
        <span className="flex-1 md:flex-[0] whitespace-nowrap">{label}</span>
      )}
    </LoadingButton>
  );
};

export default MuiLoadingButton;
