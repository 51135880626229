import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/UserSlice";
import SchedularSlice from "./slices/SchedularSlice";
import ResultSlice from "./slices/ResultSlice";
import NewTestSlice from "./slices/NewTestSlice";
import FetchTestItemsSlice from "./slices/FetchTestItemsSlice";
import CompletedStepSlice from "./slices/CompletedStepSlice";
import { schedularlistApi } from "./apis/schedularlistApi";
import { setupListeners } from "@reduxjs/toolkit/query";
import { fetchTestItemsApi } from "./apis/fetchTestItemsApis";
import { headCellsApi } from "./apis/HeadCellsApi";
import { resultFileApi } from "./apis/ResultFileApi";
import { fetchRebotApi } from "./apis/fetchRebotApi";
import storage from 'redux-persist/lib/storage';
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['users'],
};

const persistedReducer = persistReducer( persistConfig,combineReducers({
  users: userSlice,
  schedular: SchedularSlice,
  result: ResultSlice,
  newTestItems: FetchTestItemsSlice,
  newTestPayload: NewTestSlice,
  testSteps: CompletedStepSlice,
  [schedularlistApi.reducerPath]: schedularlistApi.reducer,
  [fetchTestItemsApi.reducerPath]: fetchTestItemsApi.reducer,
  [headCellsApi.reducerPath]: headCellsApi.reducer,
  [resultFileApi.reducerPath]: resultFileApi.reducer,
  [fetchRebotApi.reducerPath] : fetchRebotApi.reducer,
}));

export const store = configureStore({
  reducer: persistedReducer,

  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware()
      .concat(schedularlistApi.middleware)
      .concat(fetchTestItemsApi.middleware)
      .concat(headCellsApi.middleware)
      .concat(resultFileApi.middleware).concat(fetchRebotApi.middleware);
  },
});

setupListeners(store.dispatch);

export const persistor = persistStore(store);