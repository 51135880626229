import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { token } from "../../Raw/data";

const fetchListing = createAsyncThunk("schedular/fetch", async () => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/admin/getSchedularList`,
    null,
    {
      headers: {
        "Content-Type": "application/json", // Adjust the content type based on your API requirements
        token: token(),
      },
    }
  );

  return response.data;
});


const fetchResultListing = createAsyncThunk("result/instanceFetch", async () => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/admin/getNotifyList`,
    null,
    {
      headers: {
        "Content-Type": "application/json", // Adjust the content type based on your API requirements
        token: token(),
      },
    }
  );

  return response.data;
});

const deleteResultListing = createAsyncThunk("result/instanceDelete", async (ids) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/admin/deleteNotifyList`,
    {
      resIds:ids
      
    },
    {
      headers: {
        "Content-Type": "application/json", // Adjust the content type based on your API requirements
        token: token(),
      },
    }
  );

  return response.data;
});


export { fetchListing,fetchResultListing, deleteResultListing };
