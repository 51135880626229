import React, { useEffect, useState } from "react";

import { Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";

import {
  StepDropDown,
  StepGreenHeading,
  StepInputField,
  Switcher,
} from "./Common";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTestPayload,
  updateTestPayload,
} from "../../../store/slices/NewTestSlice";
import {
  useFetchDedicatedRobotQuery,
  useFetchRobotCitiesMutation,
  useFetchRobotCitiesQuery,
  useFetchRobotCountriesQuery,
} from "../../../store/apis/fetchTestItemsApis";
import CheckboxesTags from "./AutoCompleteCheckbox";
import MultipleSelectCheckmarks from "./AutoCompleteCheckbox";
import { getratmode, setratmode } from "../../../Raw/data";

export const Robot = ({ name , disabled }) => {
  const { [name]: Robot } = useSelector((state) => {
    return state.newTestPayload;
  });


  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleChange = () => {
    if (!Robot) {
      dispatch(
        updateTestPayload({
          name: name,
          value:
            name == "RobotA"
              ? {
                  device1id: 9477,
                  device1port: 0,
                  resource: "dedicated",
                  robotname: "",
                  device1country: 9999,
                  device1area: "Any",
                  device1ratmode: [255],
                }
              : name == "RobotB"
              ? {
                  device2id: 9477,
                  device2port: 0,
                  resource: "dedicated",
                  robotname: "",
                  device2country: 9999,
                  device2area: "Any",
                  device2ratmode: [255],
                }
              : {
                  device3id: 9477,
                  device3port: 0,
                  resource: "dedicated",
                  robotname: "",
                  device3country:9999,
                  device3area: "Any",
                  device3ratmode: [255],
                },
        })
      );
    } else {
      dispatch(deleteTestPayload(name));
    }
  };

  const [exampleports,setexampleports] = useState([
    { id: 0, name: "0" },
    { id: 1, name: "1" },
  ]);

  const handlePortChange = (e) => {
    dispatch(
      updateTestPayload({
        name: name,
        value:
          name == "RobotA"
            ? {
                ...Robot,
                device1port: e.target.value,
              }
            : name == "RobotB"
            ? {
                ...Robot,
                device2port: e.target.value,
              }
            : {
                ...Robot,
                device3port: e.target.value,
              },
      })
    );
  };

  const onResouceChange = () => {
    dispatch(
      updateTestPayload({
        name: name,
        value: {
          ...Robot,
          resource: Robot.resource == "shared" ? "dedicated" : "shared",
        },
      })
    );
  };

  const onNameChange = (e) => {

  
    dispatch(
      updateTestPayload({
        name: name,
        value:  
        name == "RobotA"
        ? {
            ...Robot,
            device1id: e.target.value,
          }
        : name == "RobotB"
        ? {
            ...Robot,
            device2id: e.target.value,
          }
        : {
            ...Robot,
            device3id: e.target.value,
          },
      })
    );

    setexampleports(robotslist.data.find((x)=>x.id===e.target.value).ports.map((y)=>{

      return  {id:y,name:y}
    }));

  };



  const [cities, setcities] = useState([]);
  const onCountryChange = (e) => {
    dispatch(
      updateTestPayload({
        name: name,
        value:
          name == "RobotA"
            ? {
                ...Robot,
                device1area: "Any",
                device1country: e.target.value,
              }
            : name == "RobotB"
            ? {
                ...Robot,
                device2area: "Any",
                device2country: e.target.value,
              }
            : {
                ...Robot,
                device3area: "Any",
                device3country: e.target.value,
              },
      })
    );

    
  };
  const {data:fetchCities, isFetching:cityfetch} = useFetchRobotCitiesQuery(Robot?.device1country?Robot.device1country:Robot?.device2country?Robot.device2country:Robot?.device3country);
  const {
    data: countrylist,
    error,
    isFetching:fetchCountries,
  } = useFetchRobotCountriesQuery();

  const {
    data: robotslist,
    error:Robotserror,
    isFetching:robotfetching,
  } = useFetchDedicatedRobotQuery();



  useEffect(()=>{
    if(Robot?.device1id){
      if(robotslist?.data)
    setexampleports(robotslist?.data.find((x)=>x.id==Robot.device1id)?.ports.map((y)=>{

      return  {id:y,name:y}
    }))
  }
  },[])
  const onCityChange = (e) => {
   
    dispatch(
      updateTestPayload({
        name: name,
        value:
          name == "RobotA"
            ? {
                ...Robot,
                device1area: e.target.value,
              }
            : name == "RobotB"
            ? {
                ...Robot,
                device2area: e.target.value,
              }
            : {
                ...Robot,
                device3area: e.target.value,
              },
      })
    );
  };

  // const fetchCitiesWrapper = (id) => {
  //   setLoading(true);
  //   fetchCities(id).then((result) => {
  //     console.log("city ", result);
  //     setLoading(false);
  //     setcities(
  //       result?.data?.data?.map((obj, i) => {
  //         return {
  //           name: obj.area_name,
  //           id: obj.area_name,
  //         };
  //       })
  //     );
  //   });
  // };



  

  const onTechChange = (arr) =>{

    dispatch(
      updateTestPayload({
        name: name,
        value:
          name == "RobotA"
            ? {
                ...Robot,
                device1ratmode:setratmode(arr),
              }
            : name == "RobotB"
            ? {
                ...Robot,
                device2ratmode : setratmode(arr),
              }
            : {
                ...Robot,
                device3ratmode:  setratmode(arr),
              },
      })
    );


  }
  
  
  return (
    <>
      <Stack direction="column" rowGap="17px">
        <div>
        <FormControlLabel
          sx={{ fontWeight: "600 !important",cursor:"pointer" }}
          control={
            <Checkbox checked={Robot ? true : false} disabled={disabled} onChange={handleChange} />
          }
          label={
            <Typography variant="body1" style={{ fontWeight: "bold" }}>
              {name == "RobotA"
                ? "Robot A"
                : name == "RobotB"
                ? "Robot B"
                : "Robot C"}
            </Typography>
          }
        />
        </div>
        {Robot && (
          <>
            <Switcher
              switcher={Robot.resource == "shared" ? true : false}
              handleChange={onResouceChange}
              disabled={disabled}
            />
            <Stack direction="row" columnGap="30px">
            
              {Robot.resource == "shared" ? (
                <>
                  <StepDropDown
                    label="Country"
                    data={
                      countrylist && countrylist.data
                        ? [{ id: 9999, name: "Any" },...countrylist.data]
                        : []
                    }
                    value={
                      name == "RobotA"
                        ? Robot.device1country
                        : name == "RobotB"
                        ? Robot.device2country
                        : Robot.device3country
                    }
                    handleChange={onCountryChange}
                    width="180px"
                    loading={fetchCountries}
                    disabled={disabled}
                  />
                  <StepDropDown
                    label="City"
                    data={fetchCities?.data?[{ id: "Any", name: "Any" },...fetchCities.data.map((obj)=>{return{name:obj?.area_name,id:obj?.area_name}})]:[]}
                    handleChange={onCityChange}
                    value={
                      name == "RobotA"
                        ? Robot.device1area
                        : name == "RobotB"
                        ? Robot.device2area
                        : Robot.device3area
                    }
                    loading={cityfetch}
                    width="180px"
                    disabled={disabled}
                  />
               
                 
                  <MultipleSelectCheckmarks disabled={disabled} value={name=="RobotA"?getratmode(Robot.device1ratmode):name=="RobotB"?getratmode(Robot.device2ratmode):getratmode(Robot.device3ratmode)} handleChange={onTechChange} />
                 
                </>
              ) : (<>
                <StepDropDown
                label="Robot name"
                data={robotslist?.data?robotslist.data:[]}
                value={
                  name == "RobotA"
                    ? Robot.device1id
                    : name == "RobotB"
                    ? Robot.device2id
                    : Robot.device3id
                }
                handleChange={onNameChange}
                width="280px"
                disabled={disabled}
              />
           
                <StepDropDown
                  data={exampleports}
                  value={
                    name == "RobotA"
                      ? Robot.device1port
                      : name == "RobotB"
                      ? Robot.device2port
                      : Robot.device3port
                  }
                  handleChange={handlePortChange}
                  label="Port"
                  width="180px"
                  disabled={disabled}
                />
                </>
              )}
            </Stack>
          </>
        )}
      </Stack>
    </>
  );
};



function Step3({ payload }) {
  const [error,seterror]=useState();
  const dispatch = useDispatch();
  const onMaxDelayChange = (e) =>{
    if(e.target.value>=0 && e.target.value<=999999)
    {
      seterror("")
      dispatch(updateTestPayload({
        name:"maxrobotdelay",
        value:e.target.value

      }))
    }
    else{
      seterror("Value must be between 0 and 999999")
    }

  }


  return (
    <>
      <StepGreenHeading label="Select Robot" />
      <Stack direction="column" rowGap={1}>
        <Robot name="RobotA" />
        <Robot name="RobotB" />
        <Robot name="RobotC" />

        <div className="flex flex-col md:flex-row gap-3 ">
          <StepInputField
            label="Max robot delay"
            value={payload.maxrobotdelay}
            width="390px"
            handleChange={onMaxDelayChange}
          />
        </div>
      </Stack>
    </>
  );
}

export default Step3;
