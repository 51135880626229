import { Route, Routes } from "react-router-dom";
import Login from "../components/auth/login/Login";
import Forget from "../components/auth/login/Forget";
import Reset from "../components/auth/login/Reset";
import MainPage from "../components/dashboard/MainPage";

const MainRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route exact path="/dashboard" element={<MainPage />} />
      <Route exact path="/forget-password" element={<Forget />} />
      <Route exact path="/reset-password" element={<Reset />} />
      <Route exact path="*" element={<Login />} />
      
    </Routes>
  );
};

export default MainRoutes;
