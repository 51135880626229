import React, { useEffect, useState } from "react";
import { IoSearch } from "react-icons/io5";
import {
  CrossIcon,
  SearchIcon,
  VerticalDividerIcon,
} from "../../customIcons/customIcons";
import { Tooltip } from "@material-ui/core";

const TableSearch = ({ searchQuery, setSearchQuery,selectedTag,setSelectedTag }) => {
  const [showSearch, setshowSearch] = useState(false);
  
  const handleTagClick = (tag) => {
    setSelectedTag(tag);
  };

  const renderTag = (tag) => {
    const isSelected = selectedTag === tag;

    return (
      <div
        key={tag}
        onClick={() => handleTagClick(tag)}
        className={`flex items-start justify-center cursor-pointer text-[#50514A] text-[14px] font-[600] px-4 py-2 border border-solid border-primary rounded-3xl ${
          isSelected ? "bg-primary text-secondary" : ""
        }`}
      >
        {tag}
      </div>
    );
  };

  const tags = ["Anywhere", "Name", "Test", "Description"];
  return (
    <div className="flex flex-col gap-4 relative z-[4] bg-white">
      {showSearch ? (
        <div className="absolute z-40 space-y-3 right-0 -top-5">
          <div className="flex gap-2 items-center px-4 py-2 w-[600px] border border-solid border-[#D6D6D6] rounded-3xl">
            <SearchIcon />
            <input
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                 }}
              className="text-[14px] w-[80%] border border-transparent focus:outline-none focus:ring focus:border-transparent focus-visible:ring-transparent focus-visible:border-transring-transparent"
            />
            <div className="flex items-center gap-3">
              <div className="text-[#9E9F9A] text-[14px] cursor-pointer" onClick={()=>setSearchQuery("")}>Clear</div>
              <VerticalDividerIcon />
              <CrossIcon
                onClick={() => setshowSearch(false)}
                className="cursor-pointer"
              />
            </div>
          </div>
          {searchQuery && (
            <div className="space-y-3 bg-white px-5 py-4 w-[600px] rounded-lg shadow-custom z-40 relative">
              <div className="text-[#9E9F9A] text-[14px] font-[500]">
                Search
              </div>
              <div className="flex items-center gap-4">
                {tags.map(renderTag)}
              </div>
            </div>
          )}
        </div>
      ) : (
        <Tooltip sx={{ fontSize: '30px' }} title="Search" arrow>
          <div
            onClick={() => setshowSearch(true)}
            className="flex items-center cursor-pointer justify-center px-4 py-2 border border-solid border-[#E4E3E3] rounded-3xl"
          >
            <SearchIcon />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default TableSearch;
