import { useState } from "react";

const useModal = () => {
  const [open, setOpen] = useState(false);
  const [data,setData] = useState();
  const handleOpen = (incomingdata) => {
    setOpen(true);
    console.log("incoming data" , incomingdata)
    setData(incomingdata)
  };

  const handleClose = () => {
    setOpen(false);
  };



  return [open,data,handleOpen,handleClose];


};

export default useModal;
