import * as React from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import InputBase from "@mui/material/InputBase";
import Chip from "@mui/material/Chip";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  { name: "Any", id: 255 },
  { name: "5G", id: 8 },
  { name: "4G", id: 4 },
  { name: "3G", id: 2 },
];



export default function MultipleSelectChip({ handleChange, value , disabled}) {
  
  const [personName, setPersonName] = React.useState(value ? value : []);

  const handleInternalChange = (event, value2) => {

    const {
      target: { value },
    } = event;

    if (value2.props.value === "Any") {
      // If "Any" is selected, select all options
      setPersonName(names.map((obj) => obj.name));
    } else {
      // Toggle selection for other options
      setPersonName(
        // On autofill, we get a stringified value.
        typeof value === "string" ? value.split(",") : value.filter((x) => x !== "Any")
      );
    }
  };

  React.useEffect(() => {
    handleChange(personName);
  }, [personName]);


  return (
    <div className="flex flex-col gap-y-2 ">
      <label className="text-base font-semibold" htmlFor="test">
        Techno
      </label>
      <FormControl>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={personName}
          disabled={disabled}
          onChange={handleInternalChange}
          input={
            <InputBase
              sx={{
                borderRadius: "24px",
                border: "1px solid #D6D6D6",
                display: "flex",
                width: "250px",
                height: "45px",
                padding: "12px 24px",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            />
          }
          renderValue={(selected) => (
            <Box sx={{ display: "flex", gap: 0.5 }}>
              {selected.includes("Any") ? (
                <Chip key="Any" label="Any" />
              ) : (
                selected.map((value) => (
                  <Chip key={value.id} label={value} />
                ))
              )}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {names.map((obj, i) => (
            <MenuItem key={i} value={obj.name} sx={{backgroundColor:"#ffff !important"}} >
              <Checkbox checked={personName.includes(obj.name)} />
              {obj.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
