import React from "react";
// import leftImage from "../assets/images/authBg.png";
import leftVideo from "../assets/videos/loginBg.mp4"
import logo from "../assets/images/logo.png";
// import Login from "../components/auth/login/Login";
// import Forget from "../components/auth/login/Forget";
// import Reset from "../components/auth/login/Reset";
// import Lottie from "react-lottie";
// import animateData from '../utils/helperdata/loginBg.json'
const LoginPage = ({ children }) => {
  // const defaultOptions = {
  //   loop: true,
  //   autoplay: true,
  //   animationData: animateData,
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice",
  //   },
  // };
  return (
    <div className="max-h-screen  w-full flex items-center justify-center">
      <div className="hidden md:block md:w-[50%] ">
        
        <video
          className="w-full h-[100vh] object-cover rounded-r-[25px]"
          autoPlay loop muted
        >
          <source src={leftVideo} type="video/mp4"></source>
          <source src={leftVideo} type="video/ogg"></source>
          Your browser does not support HTML video.

        </video>
      
        {/* <Lottie
          isClickToPauseDisabled={true}
          onClick={(e) => e.stopPropagation()}
          style={{ cursor: "default" }}
          options={defaultOptions}
          height={'100vh'}
          width={'100%'}
        /> */}

      </div>

      <div className="w-[100%] md:w-[50%] flex flex-col md:mt-8 px-[20px] py-3 lg:px-[80px] xl:px-[150px]">
        <div className="flex justify-center w-full">
          <img src={logo} alt="Background" className="w-[130px] mb-6" />
        </div>
        {children}
      </div>
    </div>
  );
};

export default LoginPage;
