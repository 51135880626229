import { useSelector } from "react-redux";

export const schedulerTableHeads = [
  { id: "collapsed", label: "", isChecked: false },
  { id: "id", label: "TASK ID", isChecked: true },
  { id: "name", label: "NAME", isChecked: true },
  { id: "testname", label: "TEST", isChecked: true },

  { id: "status", label: "STATUS", isChecked: true },
  { id: "triggertype", label: "TRIGGER", isChecked: true },
  { id: "lastruntime", label: "LAST RUN", isChecked: true },
 
  { id: "dataname", label: "PARAMETER LIST", isChecked: true },
  { id: "author", label: "USER", isChecked: true },
  { id: "comment", label: "DESCRIPTION", isChecked: false },
  {
    id: "dashboardreference",
    label: "DASHBOARD REFERENCE",
    isChecked: false,
  },
  { id: "isi_number", label: "INSTANCE", isChecked: false },
   { id: "device1name", label: "#A ROBOT", isChecked: false },
  { id: "device1port", label: "#A PORT", isChecked: false },
  { id: "device1country", label: "#A COUNTRY", isChecked: false },
  { id: "device1area", label: "#A AREA", isChecked: false },
  { id: "device1ratmode", label: "#A TECHNO", isChecked: false },
  { id: "device2name", label: "#B ROBOT", isChecked: false },
  { id: "device2port", label: "#B PORT", isChecked: false },
  { id: "device2country", label: "#B COUNTRY", isChecked: false },
  { id: "device2area", label: "#B AREA", isChecked: false },
  { id: "device2ratmode", label: "#B TECHNO", isChecked: false },
  { id: "device3name", label: "#C ROBOT", isChecked: false },
  { id: "device3port", label: "#C PORT", isChecked: false },
  { id: "device3country", label: "#C COUNTRY", isChecked: false },
  { id: "device3area", label: "#C AREA", isChecked: false },
  { id: "device3ratmode", label: "#C TECHNO", isChecked: false },
  { id: "author", label: "AUTHOR", isChecked: false },
  {id:"lasterror" , label : "Last Error" , isChecked:false},
  {id:"task_user_name",label:"Task User Name" , isChecked:false},
  { id: "LOGGING", label: "LOGGING", isChecked: false },
  { id: "verbose", label: "VERBOS", isChecked: true },

  { id: "inst_id", label: "INSTANCE ID", isChecked: true },
  { id: "id_partition", label: "PARTITION", isChecked: false },
  { id: "tasktype", label: "TASK TYPE", isChecked: false },
  { id: "ACTIONS", label: "ACTIONS", isChecked: true },
];
export const resultTableHeads = [
  { id: "id", label: "ID", isChecked: true },
  { id: "testname", label: "TEST", isChecked: true },
  { id: "taskname", label: "TASK", isChecked: true },
  { id: "col1", label: "COLUMN #1", isChecked: true },
  { id: "col2", label: "COLUMN #2", isChecked: true },
  { id: "col3", label: "COLUMN #3", isChecked: true },
  { id: "col4", label: "COLUMN #4", isChecked: true },
  { id: "col5", label: "COLUMN #5", isChecked: true },
  { id: "col6", label: "COLUMN #6", isChecked: true },
  { id: "col7", label: "COLUMN #7", isChecked: false },
  { id: "col8", label: "COLUMN #8", isChecked: true },
  { id: "col9", label: "COLUMN #9", isChecked: false },
  { id: "col10", label: "COLUMN #10", isChecked: false },
  { id: "col11", label: "COLUMN #11", isChecked: false },
  { id: "col12", label: "COLUMN #12", isChecked: false },
  { id: "col13", label: "COLUMN #13", isChecked: false },
  { id: "col14", label: "COLUMN #14", isChecked: false },
  { id: "col15", label: "COLUMN #15", isChecked: false },
  { id: "col16", label: "COLUMN #16", isChecked: false },
  { id: "col17", label: "COLUMN #17", isChecked: false },
  { id: "col18", label: "COLUMN #18", isChecked: false },
  { id: "col19", label: "COLUMN #19", isChecked: false },
  { id: "col20", label: "COLUMN #20", isChecked: false },
  { id: "col21", label: "COLUMN #21", isChecked: true },
  { id: "file_name1", label: "FILE #1", isChecked: true },
  { id: "file_name2", label: "FILE #2", isChecked: true },
  { id: "cmdtime", label: "CMD TIME", isChecked: true },
  { id: "auth_name", label: "AUTHOR", isChecked: true },
];

export const resultTableData = [
  {
    id: 1,
    TEST: "Text",
    TASK: "Text",
    AUTHOR: "Text",
    STATUS: "SUCCESSFUL",
    "FILE #1": "Text",
    TIME: "Text",
    "FILE #2": "Text",
  },
  {
    id: 2,
    TEST: "Text",
    TASK: "Text",
    AUTHOR: "Text",
    STATUS: "FAILED",
    "FILE #1": "Text",
    TIME: "Text",
    "FILE #2": "Text",
  },
];



export const simViewTableHeads = [
  { id: "status_color", label: "Status", isChecked: true },
    // { id: "status", label: "Status", isChecked: true },
    { id: "subscribernum", label: "Subscriber number", isChecked: true },
    { id: "provider", label: "Mobile provider", isChecked: true },
    { id: "imsi", label: "IMSI", isChecked: true },
    { id: "iccid", label: "ICCID", isChecked: true },
    { id: "sim_rack_position", label: "Sim rack position", isChecked: true },
    { id: "comment", label: "Comments", isChecked: true },
    { id: "gsm_device", label: "GSM devices", isChecked: true },
    { id: "apn", label: "APN", isChecked: true },
    { id: "apnuser", label: "APN login", isChecked: true },
    { id: "apnpass", label: "APN Password", isChecked: true }
  
]

export const statusColors = [
  { id: "EMPTY" , color:"from-slate-100 to-slate-50" },
  { id: "BEZEN2" , color:"from-gray-500 to-gray-300" },
  { id: "BLACK_BLINK" , color:"from-black to-slate-400" },
  { id: "GREEN" , color:"from-green-500 to-green-400"},
  { id: "GREEN_LIGHT" , color:"from-green-300 to-green-200" },
  { id: "GREEN_LIGHT_BLINK",color:"from-green-200 to-green-100" },
  { id: "BLACK" , color:" from-black to-slate-400"},
  { id: "WHITE", color:"from-slate-100 to-slate-50" },
  { id: "EURO" , color:"from-slate-50 to-slate-100" },
  { id: "EXCLAMATION" , color:"from-slate-50 to-slate-100e"},
  { id: "EXCLAMATION_BLANC" , color:"from-slate-50 to-slate-100"},
  { id: "YELLOW" , color:"from-yellow-300 to-yellow-200" },
  { id: "ORANGE" , color:"from-orange-400 to-orange-300" },
  { id: "RED" , color:"from-red-400 to-red-300"}
]




export const formattedDateFunction = (timestamp) => {
  if (timestamp == 0) return "";

  const date = new Date(timestamp * 1000); // Convert seconds to milliseconds

  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false, // Use 24-hour format
  };

  const formattedDate = date.toLocaleString(undefined, options); // Get a localized string representing the date and time
  return formattedDate;
};




