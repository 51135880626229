import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { token } from "../../Raw/data";

const fetchRebotApi = createApi({
  reducerPath: "robotmap",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}`,
    prepareHeaders: (headers, { getState }) => {
      if (token()) {
        headers.set("token", token());
      }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),

  endpoints(builder) {
    return {
      fetchRobot: builder.query({
        query: (obj) => {
          return {
            url: "/admin/get-devices-list",
            method: "GET",
          };
        },
      }),
    };
  },
});

export const {useFetchRobotQuery} = fetchRebotApi;
export {fetchRebotApi}