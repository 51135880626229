import React, { useEffect, useState } from "react";
import { InputBase, FormControlLabel, Checkbox } from "@mui/material";
import Password from "../../common/Password";
import LoginPage from "../../../pages/LoginPage";
import { useNavigate } from "react-router-dom";
import MuiLoadingButton from "../../common/MuiLoadingButton";
import { useThunk } from "../../../hooks/use-thunk";
import { loginUser } from "../../../store/thunks/LoginUser";
import { useSelector } from "react-redux";
import { set } from "lodash";
import { token } from "../../../Raw/data";

const Login = () => {
  const [loginData, setLoginData] = useState();
  console.log("🚀 ~ file: Login.js:14 ~ Login ~ loginData:", loginData);
  const [password, setpassword] = useState();
  const [username, setUsername] = useState();
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const navigate = useNavigate();
  const [logIn, isLoading, isError] = useThunk(loginUser);
 

  const handleLogin = async (e) => {
    setLoginData();
    e.preventDefault();
    logIn({ username: username, password: password });
  };

  const user = useSelector((state) => {
    return state.users;
  });
  console.log(user,"user");
  useEffect(() => {
    if (isError?.message) {
      alert(isError.message);
    }
    // Check if user data is present and loginData is not already set
    if (user?.data && !loginData) {
      setLoginData(user?.data);
    }
    if (user.isSigned) {
      navigate("/dashboard");
    }
  }, [isError, user, loginData]);

  if(user.isSigned)
  return <div></div>

  
  return (
    <LoginPage>
      {" "}
      <h2 className="text-3xl font-bold mb-2">Sign In</h2>
      <p className="text-[16px]  font-[500] mb-5 text-[#50514A]">
        Please enter your username and password to sign in
      </p>
      <p className="text-md"></p>
      <form onSubmit={handleLogin} className="w-full">
        <div className="mb-6">
          <p className="text-[16px] font-[600]  mb-2">Username</p>
          <InputBase
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            type="text"
            className="w-full border font-[500] text-[14px]  rounded-full border-solid border-[#DADDD1] px-[20px] py-[3px] text-[#66685F] focus-within:border-primary focus-within:ring-[0px 2px 7px 0px rgba(128, 151, 51, 0.40)"
            placeholder="User_Demo"
          />
          {usernameError && (
            <p className="text-red-500 text-[14px] mt-1">{usernameError}</p>
          )}
        </div>
        <div className="mb-6">
          <p className="text-[16px] font-[600]  mb-2">Password</p>
          <Password password={password} setpassword={setpassword} />
          {passwordError ? (
            <p className="text-red-500 text-[14px] mt-1">{passwordError}</p>
          ) : loginData?.status == false ? (
            <p className="text-red-500 text-[14px] mt-1">
              Incorrect username or password. Please try again
            </p>
          ) : null}
        </div>
        <div className="flex justify-between items-center">
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color: "#AEC954",
                  "&.Mui-checked": {
                    color: "#AEC954",
                  },
                }}
              />
            }
            label="Remember me"
          />
          <p
            className="text-primary cursor-pointer text-[14px] font-[600]"
            onClick={() => navigate("/forget-password")}
          >
            Forgot password?
          </p>
        </div>

        <div className="mt-4">
          <MuiLoadingButton
            type="submit"
            label="Sign In"
            loading={isLoading}
            isDisabled={!username || !password ? true : false}
          />
        </div>
      </form>
    </LoginPage>
  );
};

export default Login;
