import { createSlice } from "@reduxjs/toolkit";
import { updateTestPayload } from "./NewTestSlice";


let initialStateBlue = 
    {
        activeStep:0,
        completedStep:0,
     }


const completedStepSlice = createSlice({

    name:"newtest",
    initialState:initialStateBlue,
    reducers:{
        updateActiveStep(state,action){

            state.activeStep=action.payload
          },
          resetStepState(state,action){
           
          return initialStateBlue
                
          },

    },
    extraReducers(builder){

      
    }


})


export const {updateActiveStep,resetStepState} = completedStepSlice.actions;

export default completedStepSlice.reducer