import { createSlice } from "@reduxjs/toolkit";
import { getTestNameList } from "../thunks/FetchTestItems";

const currentDate = new Date();

const formattedDate = currentDate.toLocaleString('en-US', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false,
});

const [month, day, yearAndTime] = formattedDate.split('/');
const [year, time] = yearAndTime.split(',');

// Reconstructing the desired format
const customFormattedDate = `${year}/${month}/${day} ${time}`;
let newTestInitialState={
  verbose: 2,
  duration: 360,
  maxrobotdelay: 55,

  triggertype:1,
  trigger_repeat:0,
  triggervalue: customFormattedDate,
  triggerstopvalue: 0,
  rb_trigger:0,
  taskflags:0,
  restart:0,
  dataid:0,
  repeat_test:1,
  restarttype:0,
  repeat_test_delay:0,
  Sim1:{
    country:{id:0,name:"Any"},
    carrier:{id:0,name:"Any"},
    group:{id:1,name:"None"},
  },
  Sim2:{
    country:{id:0,name:"Any"},
    carrier:{id:0,name:"Any"},
    group:{id:1,name:"None"},
  },
  Sim3:{
    country:{id:0,name:"Any"},
    carrier:{id:0,name:"Any"},
    group:{id:1,name:"None"},
  }
};

const newTestSlice = createSlice({
  name: "newtest",
  initialState:newTestInitialState ,
  reducers: {
    resetState(state,action){
   
    return newTestInitialState
          
    },

    EditTestPayload(state,action){

      return action.payload
    },
    updateTestPayload(state, action) {
   
      state[action.payload.name] = action.payload.value;
    },

    deleteTestPayload(state, action) {
      delete state[action.payload];
    },
  },
});

export const { updateTestPayload, deleteTestPayload,resetState,EditTestPayload } = newTestSlice.actions;
export default newTestSlice.reducer;
