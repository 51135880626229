import React from "react";
import Sidebar from "../components/sidebar/Sidebar";

const DashboardLayout = ({ children }) => {
  return (
    <div className="flex h-screen w-full">
      {/* sidebar */}
      {/* <div className="w-[15%] h-full bg-[#F4F4F4]">
        <Sidebar />
      </div> */}
      {/* main dashboard */}
      <div className="w-[100%] h-full">
        <div className="h-[100vh]">{children}</div>
      </div>
    </div>
  );
};

export default DashboardLayout;
