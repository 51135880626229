import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { StepGreenHeading, StepInputField } from "./Common";
import { Checkbox, LinearProgress, Stack } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import { useUpdateDataColllectionMutation } from "../../../store/apis/fetchTestItemsApis";
import Swal from "sweetalert2";
import LoginBtn from "../../common/LoginBtn";
import { useSelector } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  //   width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Example 1", 159, 6.0, 24, 4.0),
  createData("Example 2", 237, 9.0, 37, 4.3),
  createData("Example 3", 262, 16.0, 24, 6.0),
  createData("Example 4", 305, 3.7, 67, 4.3),
  createData("Paramter 5", 356, 16.0, 49, 3.9),
];

export function BasicTable({ copydata,setcopydata,isFetching}) {
  

  const [editindex, seteditindex] = useState();


  return (
    <TableContainer component={Paper} sx={{ maxHeight: "60vh" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow className="bg-[#F4F4F4]">
            <TableCell>Variable Name</TableCell>
            <TableCell align="left">Values set #1</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
              isFetching ?  <TableRow ><TableCell colSpan={2}><LinearProgress /></TableCell></TableRow> :
              copydata&&Object.entries(copydata).map((row, index) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() => seteditindex(index)}
                >
                  <TableCell component="th" scope="row">
                    {row[0]}
                  </TableCell>
                  <TableCell align="left">
                    {row[1].includes("yes") || row[1].includes("no") ? (
                      <Checkbox checked={row[1]=="yes"} onChange={()=>setcopydata({...copydata,[row[0]]:row[1]=="yes"?"no":"yes"})}/>
                    ) : (
                      <>
                        {editindex === index ? (
                          <input
                            type="text"
                            className="outline-none"
                            style={{maxWidth:"200px"}}
                            value={row[1]}
                            onChange={(e)=>setcopydata({...copydata,[row[0]]:e.target.value})}
                            autoFocus
                          />
                        ) : (
                          row[1]
                        )}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))
          }
          {}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default function BasicModal({ data ,id , isFetching}) {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [copydata,setcopydata]=useState(data?data:{});
 
  React.useEffect(()=>{
    setcopydata(data)
  },[data])
  const [updateCollection, results] = useUpdateDataColllectionMutation();
  const [loading , setloading] = useState(false);
  const submitChange = () =>{
    setloading(true)
    updateCollection({id:id,...copydata}).then((result)=>{
      handleClose()
      setloading(false)
      if(result.data.status==true){
        
          Swal.fire({
            title: "Successfully updated parameters",
            icon: "success",
            width:'398px',
            confirmButtonColor: "#AEC954",
            padding:"24px",
            customClass: {
              confirmButton: '!rounded-3xl px-10 h-[45px]',
              popup:"!text-[14px] !text-left",
              title:"!text-[20px] !p-0 !text-left !text-[#849C36]"
            },
            confirmButtonText: "OK",
          }).then((result2) => {
            /* Read more about isConfirmed, isDenied below */
            if (result2.isConfirmed) {
            
            
            }
          });
        } else {
          Swal.fire({
            title: "Error updating parameters",
            icon: "error",
            width:'398px',
            confirmButtonColor: "#AEC954",
            padding:"24px",
            customClass: {
              confirmButton: '!rounded-3xl px-10 h-[45px]',
              popup:"!text-[14px] !text-left",
              title:"!text-[20px] !p-0 !text-left !text-[#849C36]"
            },
            confirmButtonText: "OK",
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
          });
        }
    })
  }

  const { parameterslist } = useSelector((state) => {
    return state.newTestItems;
  });


  return (
    <div>
      <button
        onClick={handleOpen}
        className="h-[45px] px-10 border-[1px] rounded-3xl flex justify-center items-center border-primary text-primary disabled:border-gray-200 disabled:text-gray-200"
      disabled={id?false:true}
     >
        Edit Parameters
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <StepGreenHeading label="Edit Parameters" />
          <Stack
            direction="row"
            columnGap="32px"
            marginTop="20px"
            marginBottom="18px"
          >
            {/* <StepInputField label="Name"  value={parameterslist.filter((x)=>x.id==id)[0]?.name}  disabled={true}/>
            <StepInputField label="Desc" value={parameterslist.filter((x)=>x.id==id)[0]?.description}   disabled={true}/> */}
          </Stack>
          <div className="h-[60vh]">
          <BasicTable isFetching={isFetching} copydata={copydata} setcopydata={setcopydata} />
          </div>
          <div className="flex justify-start items-center   gap-x-8 mt-[16px]">
            <button
              className="h-[45px] px-10 border-[1px]  rounded-3xl flex justify-center items-center border-primary text-primary"
              onClick={handleClose}
            >
              Cancel
            </button>
            {/* <button
            onClick={submitChange}
              className="h-[45px] px-10 border-[1px] rounded-3xl flex justify-center items-center border-primary bg-primary hover:bg-opacity-90 disabled:opacity-25"
              // disabled={!passValidator()}
            >
              Save
            </button> */}

            <LoginBtn loading={loading} handleClick={submitChange} name="Save"/>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
