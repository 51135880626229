import React from "react";
import {
  StepDropDown,
  StepInputField,
  StepTextArea,
  StepGreenHeading,
} from "./Common";
import { useDispatch, useSelector } from "react-redux";
import { updateTestPayload } from "../../../store/slices/NewTestSlice";

function Step1({ payload }) {
  const { testnames } = useSelector((state) => {
    return state.newTestItems;
  });

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;

    dispatch(updateTestPayload({ name: name, value: value }));
  };



  return (
    <>
      <StepGreenHeading label="Define task" />
      <div className="flex flex-col md:flex-row gap-3 lg:gap-x-[136px]">
        <StepInputField
          label="Task Name*"
          name="name"
          width="390px"
          value={payload?.name}
          handleChange={handleChange}
        />

        <StepDropDown
          label="Test Name*"
          name="testid"
          width="390px"
          data={testnames?.slice().sort((a,b)=>a.name.localeCompare(b.name))}
          value={payload.testid}
          handleChange={handleChange}
        />
      </div>
      <StepTextArea
        label="Description"
        value={testnames?.find((obj) => obj.id == payload.testid)?.description.split("\\xA").join("\n").split("\\x2C").join(",")}
      />
      <div className="flex flex-col md:flex-row gap-3 lg:gap-x-[136px]">
        <StepInputField
          label="Max test duration"
          width="390px"
          name="duration"
          handleChange={(e) => {
            e.target.value >= 0 && handleChange(e);
          }}
          value={payload?.duration}
        />

        <StepDropDown
          label="Verbose mode"
          width="390px"
          data={[
            { id: 0, name: "None" },
            { id: 1, name: "Medium" },
            { id: 2, name: "Maximum" },
          ]}
          name="verbose"
          handleChange={handleChange}
          value={payload?.verbose}
        />
      </div>
    </>
  );
}

export default Step1;
