import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { updateSelectedTab } from '../../store/slices/ResultSlice';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};



export default function BasicTabs() {
 
    
    const dispatch = useDispatch();
    const handleChange = (event, newValue) => {
        console.log("new value" , newValue)
      setValue(newValue);

        dispatch(updateSelectedTab(newValue));

    };
  
    const {tabs,selectedTab}  = useSelector((state)=>{

        return state.result;
    })

    const {testnames}  = useSelector((state)=>{

        return state.newTestItems
    })

    const [value, setValue] = React.useState(0);

  return (
    <div className='flex'>
    <Box
    sx={{
      display:"flex",
      justifyContent:"center",
      bgcolor: "#F4F4F4",
    
    }}
  >

        <Tabs   TabIndicatorProps={{
              style: { display: "none" }, // Hide the indicator
            }}
            
            value={selectedTab} onChange={handleChange} aria-label="basic tabs example">
            {   tabs?.map((state,i)=>{

                    return <Tab color='#50514A'
                    sx={{
                      textTransform: "capitalize",
                      "&.Mui-selected": {
                        backgroundColor: "#AEC954",
                        color: "#0E1200",
                        
                        padding: "16px",
                      },
                      padding: "16px !important",
                    }}  value={state} label={testnames.filter((x)=>x.id==state)[0].name}  />

            })

            }
         
        </Tabs>
  
     
    </Box>
    </div>
  );
}