import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const loginUser = createAsyncThunk("user/login", async (data) => {
  const { username, password } = data;
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/admin/login`,
    { username, password },
    {
      headers: {
        "Content-Type": "application/json", // Adjust the content type based on your API requirements
      },
    }
  );

  return response.data;
});

export { loginUser };
