import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";

import {
  StepDropDown,
  StepGreenHeading,
  StepInputField,
  StepSelectTime,
  StepWeekDays,
} from "./Common";
import { useDispatch, useSelector } from "react-redux";
import { deleteTestPayload, updateTestPayload } from "../../../store/slices/NewTestSlice";

function Step5() {
  const [value, setValue] = React.useState("female");
  const [repeat, setRepeat] = React.useState("never");
  const [error, setError] = React.useState("");
  const dispatch = useDispatch();
  var nowDate = new Date(); 
  const handleChange = (e) => {

    dispatch(
      updateTestPayload({
        name: "rb_trigger",
        value: e.target.value,
      })
    );

      if(e.target.value==1)
      {
        let startdate=nowDate.getFullYear()+'/'+(nowDate.getMonth()+1)+'/'+nowDate.getDate();
        dispatch(
          updateTestPayload({
            name: "schedulepayload",
            value: {
              startdate:startdate,
              hours:0,
              min:0,
              stopdate:startdate,
              s_hours:0,
              s_min:0,
            },
          })
        );

      }
      else{
        dispatch(
          deleteTestPayload("schedulepayload")
        );

        dispatch(
          updateTestPayload({
            name: "triggertype",
            value: 1,
          })
        );

        dispatch(
          updateTestPayload({
            name: "trigger_repeat",
            value: 0,
          })
        );

      }


  };
  const handleRepeatChange = (e) => {
    // setRepeat(e.target.value);
    dispatch(
      updateTestPayload({
        name: "triggertype",
        value: e.target.value,
      })
    );


    

    if (e.target.value == 9) {
      dispatch(
        updateTestPayload({
          name: "trigger_repeat",
          value: 5,
        })
      );

      
    }

    if (e.target.value == 6) {
      dispatch(
        updateTestPayload({
          name: "trigger_repeat",
          value: 1,
        })
      );
    }

    if (e.target.value == 7) {
      dispatch(
        updateTestPayload({
          name: "trigger_repeat",
          value: 3,
        })
      );
    }

    if(e.target.value==9 || e.target.value==7 || e.target.value==6)
    {
      dispatch(
        updateTestPayload({
          name: "schedulepayload",
          value: {...schedulepayload,triggerstop:true},
        })
      );
    }
    else{
      dispatch(
        updateTestPayload({
          name: "schedulepayload",
          value: {...schedulepayload,triggerstop:false,triggerstopchecked:false},
        })
      );

    }

  };
  const { schedulepayload, triggertype, trigger_repeat , rb_trigger} = useSelector((state) => {
    return state.newTestPayload;
  });
  const formattedDate = new Date(schedulepayload?.startdate).toLocaleDateString("en-CA");
  const formattedDateForStop = new Date(schedulepayload?.stopdate).toLocaleDateString("en-CA");
  const onStartDateChange = (e) => {
    dispatch(
      updateTestPayload({
        name: "schedulepayload",
        value: {...schedulepayload,startdate:e.target.value},
      })
    );
  };

  const onStopDateChange = (e) => {
    dispatch(
      updateTestPayload({
        name: "schedulepayload",
        value: {...schedulepayload,stopdate:e.target.value},
      })
    );
  };

  const onTimeChange = (e) => {
    dispatch(
      updateTestPayload({
        name: "schedulepayload",
        value: {...schedulepayload,[e.target.name]:e.target.value},
      })
    );
  };

  const onStopTimeChange = (e) => {
    dispatch(
      updateTestPayload({
        name: "schedulepayload",
        value: {...schedulepayload,["s_"+e.target.name]:e.target.value},
      })
    );
  };
  const onMinuteChange = (e) => {
    if (e.target.value < 5 || e.target.value == "" || e.target.value > 1440) {
      setError("Value must be between 5 and 1440");
      dispatch(
        updateTestPayload({
          name: "trigger_repeat",
          value: e.target.value,
        })
      );
    } else if (e.target.value >= 5) {
      setError("");
      dispatch(
        updateTestPayload({
          name: "trigger_repeat",
          value: e.target.value,
        })
      );
    }
  };

  const onDayChange = (e) => {
    if (e.target.value < 1 || e.target.value == "" || e.target.value.length > 3) {
      setError("Value must be between 1 and 999");
      dispatch(
        updateTestPayload({
          name: "trigger_repeat",
          value: e.target.value,
        })
      );
    } else if (e.target.value >=1) {
      setError("");
      dispatch(
        updateTestPayload({
          name: "trigger_repeat",
          value: e.target.value,
        })
      );
    }
  };

  const handletriggerstopcheck = () =>{

    dispatch(
      updateTestPayload({
        name: "schedulepayload",
        value: {...schedulepayload,triggerstopchecked:schedulepayload.triggerstopchecked?false:true},
      })
    );

  }

  const onWeekDaysChange =()=>{}
  return (
    <>
      <StepGreenHeading label="Execution time" />

      <FormControl>
        {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
          value={rb_trigger}
          onChange={handleChange}
        >
          <FormControlLabel
            value="0"
            control={<Radio />}
            className="!ml-0"
            labelPlacement="start"
            label="Start now"
          />
          <FormControlLabel
            value="1"
            control={<Radio />}
            className="!ml-0"
            labelPlacement="start"
            label="Schedule Test"
          />
        </RadioGroup>
      </FormControl>

      {rb_trigger == 1 && (
        <>
          {" "}
          <StepGreenHeading label="Schedule test run" />
          <Stack direction="row" columnGap="136px">
            <StepInputField
              label="Select date"
              type="date"
              value={formattedDate}
              handleChange={onStartDateChange}
            />

            <StepSelectTime label="Select time" hours={schedulepayload?.hours} min={schedulepayload?.min} handleChange={onTimeChange} />
          </Stack>
          <StepGreenHeading label="Repeat" />
          <FormControl >
            {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              className="items-start"
              defaultValue="female"
              name="radio-buttons-group"
              value={triggertype}
              onChange={handleRepeatChange}
            >
              <FormControlLabel
             
                value="1"
                control={<Radio />}
                className="!ml-0 flex !justify-between w-[250px]"
                labelPlacement="start"
                label="Never"
              />
              <FormControlLabel
                value="9"
                control={<Radio />}
                className="!ml-0 flex !justify-between w-[250px]"
                labelPlacement="start"
                label="Each minute"
              />
              {triggertype == 9 && (
                <StepInputField
                  label="Iterate each minute"
                  error={error}
                  value={trigger_repeat}
                  handleChange={onMinuteChange}
                  width="220px"
                />
              )}
              <FormControlLabel
                value="6"
                control={<Radio />}
                className="!ml-0 flex !justify-between w-[250px]"
                labelPlacement="start"
                label="Each day"
              />
              {triggertype == 6 && (
                <StepInputField
                  label="Iterate each day"
                  error={error}
                  value={trigger_repeat}
                  handleChange={onDayChange}
                  width="220px"
                />
              )}
              <FormControlLabel
                value="7"
                control={<Radio />}
                className="!ml-0 flex !justify-between w-[250px]"
                labelPlacement="start"
                label="Each week"
              />
            </RadioGroup>
          </FormControl>
          {triggertype == 7 && <StepWeekDays handleChange={onWeekDaysChange} label="Select days" />}
         <div> 
         
          <FormControlLabel
         
          sx={{ fontSize: "14px", fontWeight: 400, marginLeft: 0 }}
          control={
            <Checkbox
              checked={schedulepayload.triggerstopchecked}
             onChange={handletriggerstopcheck}
              sx={{
                color: "#D6D6D6",
              }}
              disabled={!schedulepayload.triggerstop}
            />
          }
          labelPlacement="start"
          label={ <StepGreenHeading label="Stop test run" />}
        />
        </div>
        {schedulepayload.triggerstopchecked &&
          <Stack direction="row" columnGap="136px">
          <StepInputField
              label="Select date"
              type="date"
              value={formattedDateForStop}
              handleChange={onStopDateChange}
              disabled={!schedulepayload.triggerstop}
            />

          <StepSelectTime  disabled={!schedulepayload.triggerstop} label="Select time"  hours={schedulepayload?.s_hours}  min={schedulepayload?.s_min} handleChange={onStopTimeChange}/>
          </Stack>
        }
        </>
      )}
    </>
  );
}

export default Step5;
