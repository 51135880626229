import { LoadingButton } from "@mui/lab";
import React from "react";

const LoginBtn = ({loading,handleClick,name,disabled}) => {

  return (
    <LoadingButton
      // className={classes.customButton}
      //    loadingClassName={classes.loadingButton}
      sx={{ width:"133px", height:"45px", color: "black", backgroundColor: "#AEC954 !important", borderRadius: "25px !important", boxShadow:"none",textTransform:"capitalize","&:hover": {
        backgroundColor:"#AEC954",
        opacity:0.9
      },
    }}
      size="medium"
      onClick={handleClick}
    //   endIcon={<IoChevronForwardOutline />}
      loading={loading}
      loadingPosition="end"
      variant="contained"
      disabled={disabled}    
      >
     {name}
    </LoadingButton>
  );
};

export default LoginBtn;
