import React, { useState } from "react";

import { StepDropDown, StepGreenHeading, StepInputField } from "./Common";
import { useDispatch } from "react-redux";
import { updateTestPayload } from "../../../store/slices/NewTestSlice";

function Step6({payload}) {
  const NoOfTimes = [
    { id: 1, name: "Never" },
    { id: 10, name: 1 },
    { id: 20, name: 2 },
    { id: 30, name: 3 },
    { id: 40, name: 4 },
    { id: 50, name: 5 },
    { id: 100, name: 10 },
    { id: 250, name: 25 },
    {id:500 , name:50},
    {id:999999, name:'Indefinitely'}

  ];

  const stopRestarting=[
    {id:0,name:"disabled"},
    {id:1,name:"If Exit Pass"},
    {id:2,name:"If Exit Fail"},
    {id:3, name:"If Exit Esc"}

  ]
  const dispatch = useDispatch() ;
  
  const onNoOfTimeChange = (e) =>{
      dispatch(updateTestPayload({
        name:"repeat_test",
        value:e.target.value

      }))  

  }

  const onStopRChange = (e) =>{
    dispatch(updateTestPayload({
      name:"restarttype",
      value:e.target.value

    }))  
  }


  const [error,seterror]= useState();
  const onRestartIntChange = (e) =>{

    
      if(e.target.value>=0 && e.target.value<=999999)
      {
        seterror("")
        dispatch(updateTestPayload({
          name:"repeat_test_delay",
          value:e.target.value
  
        }))
      }
      else{
        seterror("Value must be between 0 and 999999")
      }
  
     
  }
 
  return (
    <>
      <StepGreenHeading label="Repetitive task" />

      <StepDropDown label="Number of times" width="390px" data={NoOfTimes}  value={payload.repeat_test} handleChange={onNoOfTimeChange}/>

      <StepDropDown label="Stop restarting" width="390px" data={stopRestarting} value={payload.restarttype} handleChange={onStopRChange}/>
      <StepInputField label="Restart intervals" width="390px" value={payload.repeat_test_delay}  handleChange={onRestartIntChange} error={error}/>
    </>
  );
}

export default Step6;
