import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { BrowserRouter } from "react-router-dom";
import MainRoutes from "./routes/MainRoutes";
import { ThemeProvider, createTheme } from "@mui/material";

function App() {

  const theme = createTheme({
    palette: {
      primary: {
        main: '#AEC954', // Change the primary color
      },
    },
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            "&$Mui-selected": {
              backgroundColor: '#aec95469 !important', // Change this to your desired color
              color: 'white', // Change this to your desired text color
            },
          },
        },
      },
    },
  });
  
  return (
    <ThemeProvider theme={theme}>
    <BrowserRouter>
      <MainRoutes />
    </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
