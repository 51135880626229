import React, { useEffect, useState } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Checkbox,
  TablePagination,
  Tooltip,
} from "@material-ui/core";
import {
  Chip,
  Collapse,
  IconButton,
  LinearProgress,
  TableContainer,
} from "@mui/material";

import editIcon from "../../../assets/images/Edit.svg";
import upload from "../../../assets/images/upload.svg";
import { DropdownIcon, SortingIcon } from "../../../customIcons/customIcons";
import { getInstanceStatus } from "../../../Raw/data";
import BasicModal from "../EditModal";
import { useModifyHeadCellMutation } from "../../../store/apis/HeadCellsApi";
import { formattedDateFunction, statusColors } from "../../../utils/helperdata/HelperData";

const TestDataTable = ({
  instanceSelected,
  setInstanceSelected,
  selectedItems,
  headCells,
  dummyData,
  showCollapse = false,
  loading,
  data,
  searchQuery,
  setHeadCells,
  disableCheckbox,
  selectCount,
  onRowClick,
  orignalHeadCell,
  modifyparam,
  name,
  draggable,
  onFileClick,
  onErrorClick,
}) => {
  
  const [openRows, setOpenRows] = React.useState([]);

  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("INSTANCE");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    localStorage.getItem("rowsPerPage")
      ? parseInt(localStorage.getItem("rowsPerPage"))
      : 5
  );
  const [modifyHeadCell] = useModifyHeadCellMutation();

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = dummyData.map((n) => n.id);
      setSelected(newSelected);
      selectedItems(newSelected);
    } else {
      setSelected([]);
      selectedItems([]);
    }
  };

  const handleClick = (event, instance) => {
    event.stopPropagation();
    const selectedIndex = selected.indexOf(instance);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, instance);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);

    // Call the parent's function with the updated selected items
    if (typeof selectedItems === "function") {
      selectedItems(newSelected);
    }

  };

  const isSelected = (instance) => selected.indexOf(instance) !== -1;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = stableSort(dummyData, getComparator(order, orderBy));
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem("rowsPerPage", parseInt(event.target.value, 10));
    setPage(0);
  };


  const changeSelectedInstance = (item) => {
    if (instanceSelected.some((obj) => obj === item))
      setInstanceSelected(instanceSelected.filter((x) => x != item));
    else setInstanceSelected([...instanceSelected, item]);
  };

  const handleDragStart = (e, id) => {
    e.dataTransfer.setData("text/plain", id);
  };

  const handleDrop = (e, targetId) => {
    e.preventDefault();
    const draggedId = e.dataTransfer.getData("text/plain");

     if(["id","testname","taskname","col1","col2","col3"].some((x=>x==draggedId || x==targetId)))
     return ;

  
    const newOrder = headCells.filter((obj) => obj.id != draggedId);
    const sourceIndex = headCells.findIndex((obj) => obj.id == draggedId);

    const targetIndex = newOrder.findIndex((obj) => obj.id == targetId);

    if (sourceIndex <= targetIndex) {
      newOrder.splice(
        targetIndex + 1,
        0,
        headCells.find((x) => x.id == draggedId)
      );
    } else {
      newOrder.splice(
        targetIndex,
        0,
        headCells.find((x) => x.id == draggedId)
      );
    }
    setHeadCells(newOrder);

    modifyHeadCell({
      param: modifyparam,
      user: "administrator",
      data: {
        ...orignalHeadCell,
        columnOrder: newOrder.reduce((total, item, index) => {
          total[index] = item.id;
          return total;
        }, {}),
      },
    });
  };

  const allowDrop = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (searchQuery?.length > 2) setPage(0);
  }, [searchQuery]);



  return (
    <div className="max-w-[100%] overflow-x-auto my-4">
      <TableContainer sx={{ maxHeight: "calc(100vh - 250px)", minHeight: 100 }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          className={loading ? "mb-5" : ""}
        >
          <TableHead>
            <TableRow
              style={{
                backgroundColor: "#FAFAFA",
                borderRadius: "10px",
                color: "#50514A",
              }}
            >
              {!disableCheckbox && (
                <TableCell padding="checkbox">
                  <Checkbox
                    style={{ color: "#AEC954" }}
                    indeterminate={
                      selected.length > 0 && selected.length < dummyData.length
                    }
                    checked={selected.length === dummyData.length}
                    onChange={handleSelectAllClick}
                    inputProps={{ "aria-label": "select all desserts" }}
                  />
                </TableCell>
              )}
              {headCells.map(
                (headCell, index) =>
                  (headCell.isChecked || headCell.id == "collapsed") && (
                    <TableCell
                      style={{ whiteSpace: "nowrap", color: "#50514A" }}
                      key={index}
                      // sortDirection={orderBy === headCell.id ? order : false}
                      draggable={draggable}
                      onDragStart={(e) => handleDragStart(e, headCell.id)}
                      onDrop={(e) => handleDrop(e, headCell.id)}
                      onDragOver={allowDrop}
                    >
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={(e) => handleRequestSort(e, headCell.id)}
                        IconComponent={() => (
                          <span>
                            {/* Your custom icons or Material-UI icons */}
                            {orderBy === headCell.id ? (
                              order === "desc" ? (
                                <SortingIcon className="ml-1" />
                              ) : (
                                <SortingIcon className="rotate-180 ml-1" />
                              )
                            ) : null}
                          </span>
                        )}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    </TableCell>
                  )
              )}
              {name == "schedular" && <TableCell>ACTION</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow className="my-3 relative">
                <TableCell>
                  <LinearProgress
                    color="primary"
                    className="!absolute my-3 w-full"
                  />
                </TableCell>
              </TableRow>
            ) : sortedData.length == 0 ? (
              <TableRow>
                <TableCell
                  colSpan={headCells.filter((x) => x.isChecked).length + 1}
                  style={{ textAlign: "center" }}
                >
                  No Data found
                </TableCell>
              </TableRow>
            ) : (
              sortedData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, rowIndex) => {
                  const isItemSelected = isSelected(row.id);
                  const isRowOpen = openRows.some((x) => x == row.id);
                  // Access the matchedInstances array from the combinedArray
                  const matchedInstances = row.matchedInstances || [];
                  return (
                    <>
                      <TableRow
                        onClick={() => {
                          onRowClick && onRowClick(row);
                        }}
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={rowIndex}
                        selected={isItemSelected}
                        // onClick={(e) => handleClick(e, row.INSTANCE)}
                        style={{
                          backgroundColor: `#${
                            JSON?.parse(
                              row.display_format ? row.display_format : "{}"
                            )?.backColor
                          }`,
                        }}
                      >
                        {!disableCheckbox && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              onClick={(e) => handleClick(e, row.id)}
                              style={{ color: "#AEC954" }}
                              checked={isItemSelected}
                            />
                          </TableCell>
                        )}
                  
                        {headCells
                          .filter((x) => x.isChecked || x.id == "collapsed")
                          .map((cell, index) => (
                            <TableCell
                              style={{
                                whiteSpace: "nowrap",
                                maxWidth: "250px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                              key={index}
                            >
                              {cell.id === "isi_number" ? (
                                matchedInstances?.length > 0 ? (
                                  matchedInstances?.length
                                ) : null
                              ) : cell.id === "DESCRIPTION" ? (
                                <Tooltip
                                  title={row[cell.id]}
                                  disableHoverListener={
                                    row[cell.id]?.length <= 250
                                  }
                                >
                                  <div
                                    style={{
                                      whiteSpace: "nowrap",
                                      maxWidth: "250px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                    }}
                                  >
                                    {row[cell.id]}
                                  </div>
                                </Tooltip>
                              ) : name == "schedular" &&
                                cell.id === "status" ? (
                                <>
                                  <Chip
                                    size="medium"
                                    className="uppercase"
                                    sx={{
                                      bgcolor:
                                        row[cell.id] === "SUCCESSFUL"
                                          ? "#DFEFE2"
                                          : row[cell.id] === "FAILED"
                                          ? "#FAE3E3"
                                          : "#FEFBEB",
                                      color:
                                        row[cell.id] === "SUCCESSFUL"
                                          ? "#429E55"
                                          : row[cell.id] === "FAILED"
                                          ? "#DE4343"
                                          : "#F4D533",
                                      fontWeight: "600",
                                    }}
                                    label={getInstanceStatus(row.status)}
                                    color="success"
                                  />
                                </>
                              ) : cell.id === "lastruntime" ||
                                cell.id === "time" ||
                                cell.id == "cmdtime" ? (
                                formattedDateFunction(row[cell.id])
                              ) : cell.id === "status_color" ? (
                                <>
                                <Tooltip title={row.status} placement="right-start" enterDelay={500} leaveDelay={200}>
                                  <div
                                    
                                    className={`cursor-pointer rounded-[50%] w-[18px] h-[18px] bg-gradient-to-r  ${
                                      statusColors.filter(
                                        (x) => x.id == row[cell.id]
                                      )[0].color
                                    }`}
                                  ></div>
                                  </Tooltip>
                                  {/* <GrStatusGoodSmall title={row.status}  className="cursor-pointer"  style={{color:statusColors.filter(x=>x.id==row[cell.id])[0].color,borderRadius:"50%",fontSize:"23px"}}  /> */}
                                </>
                              ) : cell.id === "collapsed" &&
                                matchedInstances?.length > 0 ? (
                                <IconButton
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => {
                                    const updatedOpenRows = [...openRows];
                                    if (isRowOpen) {
                                      const index = updatedOpenRows.indexOf(
                                        row.id
                                      );
                                      updatedOpenRows.splice(index, 1);
                                    } else {
                                      updatedOpenRows.push(row.id);
                                    }
                                    setOpenRows(updatedOpenRows);
                                  }}
                                >
                                  {isRowOpen ? (
                                    <DropdownIcon />
                                  ) : (
                                    <DropdownIcon className={"-rotate-90"} />
                                  )}
                                </IconButton>
                              ) : cell.id === "file_name1" ? (
                                <div className="cursor-pointer" onClick={()=>onFileClick(row.file_id1)}>{row[cell.id]}</div>
                              ) :
                              cell.id === "file_name2" ? (
                                <div className="cursor-pointer" onClick={()=>onFileClick(row.file_id2)}>{row[cell.id]}</div>
                              ):  name=="results" && (cell.id == "col3" || cell.id== "col2") ? (
                                <div className="cursor-pointer" onClick={()=>onErrorClick(row)}>{row[cell.id]}</div>
                              ) : (
                                row[cell.id]
                              )}
                            </TableCell>
                          ))}
                        {name == "schedular" && (
                          <TableCell>
                            <div className="flex pl-3 ">
                              {/* <img src={upload} alt="upload icon" /> */}
                              <BasicModal
                                src={editIcon}
                                data={
                                  data.data.filter((x) => x.id == row.id)[0]
                                }
                              />

                              {/* <img
                                      src={trashIcon}
                                      onClick={() => deleteTest(row.id)}
                                      alt="trash icon"
                                      className="cursor-pointer"
                                    /> */}
                            </div>
                          </TableCell>
                        )}
                      </TableRow>
                      {showCollapse &&
                        isRowOpen &&
                        matchedInstances?.map((item, instanceIndex) => (
                          <TableRow
                            className="bg-[#f5e1b164]"
                            key={rowIndex + "i"}
                          >
                            
                            <TableCell padding="checkbox">
                              <Checkbox
                                onClick={() => changeSelectedInstance(item)}
                                style={{ color: "#AEC954" }}
                                checked={instanceSelected.some(
                                  (obj) => obj == item
                                )}
                              />
                            </TableCell>
                            {headCells
                              .filter((x) => x.isChecked)
                              .map((cell, index) => (
                                <TableCell
                                  key={index}
                                  style={{
                                    whiteSpace: "nowrap",
                                    maxWidth: "250px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    cursor: "pointer",
                                  }}
                                  className="collapse-cell"
                                  sx={{
                                    "&.MuiTableCell-body": {
                                      borderBottom: "unset",
                                    },
                                  }}
                                >
                              
                                  <Collapse
                                    in={isRowOpen}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    {cell.id === "INSTANCE" ? (
                                      instanceIndex + 1
                                    ) : cell.id === "status" ? (
                                      <>
                                        <Chip
                                          size="medium"
                                          className="uppercase"
                                          sx={{
                                            bgcolor:
                                              item.task_status == 0
                                                ? "#DFEFE2"
                                                : item.task_status == 3
                                                ? "#DFEFE2"
                                                : ["8", "10", "11", "12"].some(
                                                    (x) =>
                                                      x === item.task_status
                                                  )
                                                ? "#FAE3E3"
                                                : "#FEFBEB",
                                            color:
                                              item.task_status == 0
                                                ? "#429E55"
                                                : item.task_status == 3
                                                ? "#429E55"
                                                : ["8", "10", "11", "12"].some(
                                                    (x) =>
                                                      x === item.task_status
                                                  )
                                                ? "#DE4343"
                                                : "#F4D533",
                                            fontWeight: "600",
                                          }}
                                          label={getInstanceStatus(
                                            item.task_status
                                          )}
                                          color="success"
                                        />
                                      </>
                                    ) : cell.id === "USER" ? (
                                      item.task_user_name
                                    ) : cell.id === "lastruntime" ? (
                                      formattedDateFunction(item.last_runtime)
                                    ) : cell.id === "LOGGING" ? (
                                      item.error_code
                                    ) : cell.id === "inst_id" ? (
                                      item.id
                                    ) : cell.id === "ACTIONS" ? (
                                      <div className="flex items-center gap-1 ">
                                        <img src={upload} alt="upload icon" />
                                        <img src={editIcon} alt="edit icon" />
                                        {/* <img src={trashIcon} className="cursor-pointer" onClick={deleteTest} alt="trash icon" /> */}
                                      </div>
                                    ) : (
                                      row[cell.id]
                                    )}
                                  </Collapse>
                                </TableCell>
                              ))}
                            <TableCell />
                          </TableRow>
                        ))}
                    </>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={dummyData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default TestDataTable;

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
