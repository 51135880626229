import { Button, Checkbox, FormControlLabel, Menu } from "@mui/material";
import React, { useEffect } from "react";
import { IoSearch, IoSettingsOutline } from "react-icons/io5";
import upload from "../../../assets/images/upload.svg";
import { DeleteIcon, PlayIcon } from "../../../customIcons/customIcons";
import uploadIcon from "../../../assets/images/upload.svg";
import TestDataTable from "../../common/muiTable/TestDataTable";
import { useState } from "react";
import { MenuItem, Tooltip } from "@material-ui/core";
import {
  // resultTableData,
  resultTableHeads, simViewTableHeads,
} from "../../../utils/helperdata/HelperData";
import TableSearch from "../TableSearch";
import { useSelector } from "react-redux";
import {
  deleteResultListing,
  fetchResultListing,
} from "../../../store/thunks/FetchListing";
import { useThunk } from "../../../hooks/use-thunk";
import Swal from "sweetalert2";
import { useFetchICCIDQuery } from "../../../store/apis/fetchTestItemsApis";
const SimCardList = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [headCells, setHeadCells] = useState(simViewTableHeads);
  const [searchQuery, setSearchQuery] = useState("");
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [selectedItems, setSelectedItems] = useState();

  const { users  } = useSelector((state) => {
    return state;
  });


  const {
    data: dummyData,
    error: IccidEror,
    isFetching: fetchIccid,
  } = useFetchICCIDQuery({ countrycode: 0, provider: "Any" , partitionId: parseInt(users?.data?.user_data?.id_partition) });

  const toggleCheckbox = (id) => {
    setHeadCells((prevHeadCells) =>
      prevHeadCells.map((cell) =>
        cell.id === id ? { ...cell, isChecked: !cell.isChecked } : cell
      )
    );
  };

  const [dite,setdite] = useState([])
  useEffect(()=>{
    if(dummyData?.data.length>0)
    {
        setdite(dummyData.data.filter((x)=>x.iccid))
    }

  },[dummyData])
  const [deleteResultList, isLoading, isError, success] =
    useThunk(deleteResultListing);
  const [fetchResultList, isResultLoading, isResultError] =
    useThunk(fetchResultListing);

  useEffect(() => {
    //   fetchList();
    fetchResultList();
  }, []);

  useEffect(() => {
    if (success) {
     
      Swal.fire({
        title: "Deleted!",
        icon: "success",
        width:'398px',
            
      
        confirmButtonColor: "#AEC954",
        padding:"24px",
        customClass: {
          confirmButton: '!rounded-3xl px-10 h-[45px]',
          popup:"!text-[14px] !text-left",
          title:"!text-[20px] !p-0 !text-left !text-[#849C36]"
        },
        confirmButtonText: "OK",
      })
      fetchResultList();
    }
  }, [success]);

  useEffect(() => {
    if (isError) {
      Swal.fire({
        title: "Error deleting results...",
        icon: "error",
        width:'398px',
              
        confirmButtonText: "Ok",
        confirmButtonColor: "#AEC954",
        padding:"24px",
        customClass: {
          confirmButton: '!rounded-3xl px-10 h-[45px]',
          popup:"!text-[14px] !text-left",
          title:"!text-[20px] !p-0 !text-left !text-[#849C36]"
        },
       
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
      });
    }
  }, [isError]);
  
  const handleDeleteResult = () => {
    Swal.fire({
      title:"Deleting ?",
      text: `Do you want to delete  ${selectedItems.length==1?"this result":" these results"} ?`,
      width:'398px',
      showCancelButton: true,
      confirmButtonText: "Proceed",
      confirmButtonColor: "#AEC954",
      padding:"24px",
      customClass: {
        cancelButton: 'h-[45px] px-10 !border-[1px] !border-solid !bg-transparent !rounded-3xl flex justify-center items-center !border-primary !text-primary',
        confirmButton: '!rounded-3xl px-10 h-[45px]',
        popup:"!text-[14px] !text-left",
        title:"!text-[20px] !p-0 !text-left !text-[#849C36]"
      },
      reverseButtons: true,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteResultList(selectedItems);
      }
    });
  };

  const downloadCSV = () => {
    const csvHeader = Object.keys(dite[0]);
    const csvRows = dite.map(row =>
      csvHeader.map(fieldName => JSON.stringify(row[fieldName])).join(',')
    );
    const csvContent = [csvHeader.join(','), ...csvRows].join('\n');

    // Create a Blob with the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

    // Create a download link and trigger the download
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'Sim_cards.csv';
    link.click();
  };


  return (
    <>
      <div className="px-10 mt-10">
        {/* header buttons */}
        <div className="flex items-center justify-end mb-5">
          {selectedItems && selectedItems.length > 0 ? (
            <div className="flex items-center gap-2">
              <div
                onClick={handleDeleteResult}
                className="flex items-center justify-center gap-2 text-[14px] px-4 cursor-pointer py-2 text-[#DE4343] font-[600] border border-solid border-[#DE4343] rounded-3xl"
              >
                <DeleteIcon />
                Delete
              </div>
              <div className="flex items-center justify-center gap-2 text-[14px] px-4 cursor-pointer py-2 text-[#50514A] font-[600] border border-solid border-[#50514A] rounded-3xl">
                <img src={upload} alt="upload icon" onClick={downloadCSV} />
                Export
              </div>
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <TableSearch
                searcText={searchQuery}
                setSearchQuery={setSearchQuery}
              />
              <Tooltip title="Export" arrow>
                <div className="flex items-center cursor-pointer justify-center px-4 py-2 border border-solid border-[#E4E3E3] rounded-3xl">
                  <img className="w-[22px] h-[22px]" src={uploadIcon} onClick={downloadCSV} />
                </div>
              </Tooltip>
              <Tooltip title="Filter Columns" arrow>
                <div
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  className="flex items-center cursor-pointer justify-center px-4 py-2 border border-solid border-[#E4E3E3] rounded-3xl"
                >
                  <IoSettingsOutline fontSize={"22px"} color="#50514A" />
                </div>
              </Tooltip>
            </div>
          )}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {headCells?.map(
              (item, index) =>
                item?.label !== "" && (
                  <MenuItem key={item.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => {
                            toggleCheckbox(item.id);
                          }}
                          checked={item?.isChecked}
                          sx={{
                            color: "#AEC954",
                            "&.Mui-checked": {
                              color: "#AEC954",
                            },
                          }}
                        />
                      }
                      label={item?.label}
                    />
                  </MenuItem>
                )
            )}
          </Menu>
        </div>
        {/* table */}
        <TestDataTable
          selectedItems={(items) => setSelectedItems(items)}
          headCells={headCells}
          setHeadCells={setHeadCells}
          dummyData={[...dite].filter((item) => {
            return Object.values(item).some(
              (value) =>
                value &&
                value.toString().toLowerCase().includes(searchQuery.toLowerCase())
            );
          }).reverse()}
          showCollapse={false}
          loading={isResultLoading}
          disableCheckbox
          draggable={false}
        />
      </div>
    </>
  );
};

export default SimCardList;
