import { Button, Checkbox, FormControlLabel, Menu } from "@mui/material";
import React, { useEffect } from "react";
import { IoSearch, IoSettingsOutline } from "react-icons/io5";
import upload from "../../../assets/images/upload.svg";
import { DeleteIcon, PlayIcon } from "../../../customIcons/customIcons";
import uploadIcon from "../../../assets/images/upload.svg";
import TestDataTable from "../../common/muiTable/TestDataTable";
import { useState } from "react";
import { MenuItem, Tooltip } from "@material-ui/core";
import {
  // resultTableData,
  resultTableHeads,
} from "../../../utils/helperdata/HelperData";
import TableSearch from "../TableSearch";
import { useSelector } from "react-redux";
import {
  deleteResultListing,
  fetchResultListing,
} from "../../../store/thunks/FetchListing";
import { useThunk } from "../../../hooks/use-thunk";
import Swal from "sweetalert2";
import ResultModal from "./Modal";
import {
  useFetchCellNamesQuery,
  useFetchCellsNamesQuery,
  useFetchHeadCellQuery,
  useModifyHeadCellMutation,
} from "../../../store/apis/HeadCellsApi";
import FileModal from "./FileModal";
import useModal from "../../../hooks/use-modal";
import { useFetchFileDataQuery } from "../../../store/apis/ResultFileApi";
import ResultTabs from "./ResultTabs";
const ResultPage = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [headCells, setHeadCells] = useState(resultTableHeads);
  const [searchQuery, setSearchQuery] = useState("");

  const {
    data: fetchheadCell,
    error: errorHeadCell,
    isFetching: isFetchHeadCell,
  } = useFetchHeadCellQuery({
    param: "grid_results_CM",
    user: "administrator",
  });

  const { users } = useSelector((state) => {
    return state;
  });

  const {
    data: fetchCellsNames,
    error: errorCellsNames,
    isFetching: isFetchCellsNames,
  } = useFetchCellsNamesQuery({
    user: parseInt(users?.data?.user_data?.id_partition),
  });

  const [deleteResultList, isLoading, isError, success] =
    useThunk(deleteResultListing);
  const [fetchResultList, isResultLoading, isResultError] =
    useThunk(fetchResultListing);

  const [openError, errorData, handleOpenError, handleCloseError] = useModal();
  const [openFile, fileData, handleOpenFile, handleCloseFile] = useModal();

  const [cellNames, setCellNames] = useState([]);

  const open = Boolean(anchorEl);
  const dummyData = useSelector((state) => {
    return state.result.resultListing;
  });

  const { selectedTab } = useSelector((state) => {
    return state.result;
  });

  const { testnames } = useSelector((state) => {
    return state.newTestItems;
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [selectedItems, setSelectedItems] = useState();
  const [modifyHeadCell] = useModifyHeadCellMutation();

  const toggleCheckbox = (id) => {
    setHeadCells((prevHeadCells) =>
      prevHeadCells.map((cell) =>
        cell.id === id ? { ...cell, isChecked: !cell.isChecked } : cell
      )
    );

    let tmpHeadCell = headCells.map((cell) =>
      cell.id === id ? { ...cell, isChecked: !cell.isChecked } : cell
    );
    modifyHeadCell({
      param: "grid_results_CM",
      user: "administrator",
      data: {
        ...fetchheadCell?.data,
        columnHidden: {
          ...tmpHeadCell.reduce((result, item, index) => {
            result[item.id] = !item.isChecked;
            return result;
          }, {}),
        },
      },
    });
  };

  const handleDeleteResult = () => {
    Swal.fire({
      title: "Deleting ?",
      text: `Do you want to delete  ${
        selectedItems.length == 1 ? "this result" : " these results"
      } ?`,
      width: "398px",
      showCancelButton: true,
      confirmButtonText: "Proceed",
      confirmButtonColor: "#AEC954",
      padding: "24px",
      customClass: {
        cancelButton:
          "h-[45px] px-10 !border-[1px] !border-solid !bg-transparent !rounded-3xl flex justify-center items-center !border-primary !text-primary",
        confirmButton: "!rounded-3xl px-10 h-[45px]",
        popup: "!text-[14px] !text-left",
        title: "!text-[20px] !p-0 !text-left !text-[#849C36]",
      },
      reverseButtons: true,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteResultList(selectedItems);
      }
    });
  };

  const downloadCSV = () => {
    const csvHeader = Object.keys(dummyData[0]);
    const csvRows = dummyData.map((row) =>
      csvHeader.map((fieldName) => JSON.stringify(row[fieldName])).join(",")
    );
    const csvContent = [csvHeader.join(","), ...csvRows].join("\n");

    // Create a Blob with the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });

    // Create a download link and trigger the download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "results.csv";
    link.click();
  };

  const {
    data: fetchFileData,
    error: errorFileData,
    isFetching: isFileFetching,
  } = useFetchFileDataQuery({
    file_id: parseInt(fileData),
    partitionId: parseInt(users?.data?.user_data?.id_partition),
  });

  // const filterName = () => {
  //   let arr = headCells.map((obj) => {
  //     if (cellNames.some((x) => x.name == obj.id)) {
  //       obj.label = cellNames.find((x) => x.name == obj.id).value;
  //     }

  //     return obj;
  //   });

  //   if (testnames.filter((x) => x.id == selectedTab)[0]?.test_settings) {
  //     arr = Object.entries(
  //       testnames.filter((x) => x.id == selectedTab)[0].test_settings
  //         .resultsColHeaders
  //     ).map((obj) => {
  //       return {
  //         id: obj[0],
  //         label: obj[1].name,
  //         isChecked: obj[1].show,
  //       };
  //     });
  //   }

  //   return arr;
  // };

  //  useEffects start here
  useEffect(() => {
    //   fetchList();
    fetchResultList();
  }, []);

  useEffect(() => {
    if (success) {
      Swal.fire({
        title: "Deleted!",
        icon: "success",
        width: "398px",

        confirmButtonColor: "#AEC954",
        padding: "24px",
        customClass: {
          confirmButton: "!rounded-3xl px-10 h-[45px]",
          popup: "!text-[14px] !text-left",
          title: "!text-[20px] !p-0 !text-left !text-[#849C36]",
        },
        confirmButtonText: "OK",
      });
      fetchResultList();
    }
  }, [success]);

  useEffect(() => {
    if (isError) {
      Swal.fire({
        title: "Error deleting results...",
        icon: "error",
        width: "398px",

        confirmButtonText: "Ok",
        confirmButtonColor: "#AEC954",
        padding: "24px",
        customClass: {
          confirmButton: "!rounded-3xl px-10 h-[45px]",
          popup: "!text-[14px] !text-left",
          title: "!text-[20px] !p-0 !text-left !text-[#849C36]",
        },
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
      });
    }
  }, [isError]);

  useEffect(() => {
    if (fetchCellsNames?.data) {
      setCellNames(
        fetchCellsNames.data.map((obj) => {
          return {
            ...obj,
            name: obj.name.replace(/^RES_HEADER_/, "").toLowerCase(),
          };
        })
      );
    }
  }, [fetchCellsNames]);

  useEffect(() => {
    if (testnames.filter((x) => x.id == selectedTab)[0]?.test_settings)
    {
      setHeadCells(
           Object.entries(
            testnames.filter((x) => x.id == selectedTab)[0].test_settings
              .resultsColHeaders
          ).map((obj) => {
            return {
              id: obj[0],
              label: obj[1].name,
              isChecked: obj[1].show,
            };
          })
        
      );
    }

    
  }, [selectedTab]);

  return (
    <>
      <div className="px-10 mt-10">
        {/* header buttons */}
        
        <div className="flex items-center justify-between mb-5">
        <ResultTabs dummyData={dummyData} />
          {selectedItems && selectedItems.length > 0 ? (
            <div className="flex items-center gap-2">
              <div
                onClick={handleDeleteResult}
                className="flex items-center justify-center gap-2 text-[14px] px-4 cursor-pointer py-2 text-[#DE4343] font-[600] border border-solid border-[#DE4343] rounded-3xl"
              >
                <DeleteIcon />
                Delete
              </div>
              <div className="flex items-center justify-center gap-2 text-[14px] px-4 cursor-pointer py-2 text-[#50514A] font-[600] border border-solid border-[#50514A] rounded-3xl">
                <img src={upload} alt="upload icon" onClick={downloadCSV} />
                Export
              </div>
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <TableSearch
                searcText={searchQuery}
                setSearchQuery={setSearchQuery}
              />
              <Tooltip title="Export" arrow>
                <div className="flex items-center cursor-pointer justify-center px-4 py-2 border border-solid border-[#E4E3E3] rounded-3xl">
                  <img
                    className="w-[22px] h-[22px]"
                    src={uploadIcon}
                    onClick={downloadCSV}
                  />
                </div>
              </Tooltip>
              <Tooltip title="Filter Columns" arrow>
                <div
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  className="flex items-center cursor-pointer justify-center px-4 py-2 border border-solid border-[#E4E3E3] rounded-3xl"
                >
                  <IoSettingsOutline fontSize={"22px"} color="#50514A" />
                </div>
              </Tooltip>
            </div>
          )}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {headCells?.map(
              (item, index) =>
                item?.label && (
                  <MenuItem key={item.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => {
                            toggleCheckbox(item.id);
                          }}
                          checked={item?.isChecked}
                          sx={{
                            color: "#AEC954",
                            "&.Mui-checked": {
                              color: "#AEC954",
                            },
                          }}
                        />
                      }
                      label={item?.label}
                    />
                  </MenuItem>
                )
            )}
          </Menu>
        </div>
        {/* table */}
        <TestDataTable
          selectedItems={(items) => setSelectedItems(items)}
          headCells={headCells}
          setHeadCells={setHeadCells}
          dummyData={dummyData
            .filter((obj) => obj.test_id == selectedTab)
            .filter((item) => {
              return Object.values(item).some(
                (value) =>
                  value &&
                  value
                    .toString()
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase())
              );
            })
            .reverse()}
          orignalHeadCell={fetchheadCell?.data}
          showCollapse={false}
          loading={isResultLoading}
          modifyparam="grid_results_CM"
          name="results"
          draggable={true}
          onFileClick={handleOpenFile}
          onErrorClick={handleOpenError}
        />

        <ResultModal
          open={openError}
          data={errorData}
          handleClose={handleCloseError}
        />
        <FileModal
          open={openFile}
          isFetching={isFileFetching}
          data={fetchFileData?.data?.[0]}
          handleClose={handleCloseFile}
        />
      </div>
    </>
  );
};

export default ResultPage;
