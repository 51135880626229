import { Checkbox, FormControlLabel, Menu } from "@mui/material";
import React, { useEffect } from "react";
import { IoSettingsOutline } from "react-icons/io5";

import { DeleteIcon, PlusIcon } from "../../customIcons/customIcons";
import TestDataTable from "../common/muiTable/TestDataTable";
import { useState } from "react";
import { MenuItem, Tooltip } from "@material-ui/core";
import { schedulerTableHeads } from "../../utils/helperdata/HelperData";
import NewTest from "./NewTest";
import { useDispatch, useSelector } from "react-redux";
import TableSearch from "./TableSearch";
import {
  useDeleteScheduleTestMutation,
  useFetchScheduleListQuery,
  useUpdateInstanceTestMutation,
} from "../../store/apis/schedularlistApi";
import { getTriggerTypeFromNumber, getratmode } from "../../Raw/data";
import { useFetchRobotCountriesQuery } from "../../store/apis/fetchTestItemsApis";
import { useNavigate } from "react-router-dom";
import { resetState, updateTestPayload } from "../../store/slices/NewTestSlice";
import Swal from "sweetalert2";
import ScheduleButton from "../common/ScheduleButton";
import { resetStepState } from "../../store/slices/CompletedStepSlice";
import {
  useFetchHeadCellQuery,
  useModifyHeadCellMutation,
} from "../../store/apis/HeadCellsApi";

const Scheduler = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [headCells, setHeadCells] = useState(schedulerTableHeads);
  const [searchQuery, setSearchQuery] = useState("");

  const {users} = useSelector((state)=>{
    return state;
  })


  const { data, error, isFetching } = useFetchScheduleListQuery(users?.data?.user_data?.id_partition);
  const {
    data: fetchheadCell,
    error: isError,
    isFetching: isFetchHeadCell,
  } = useFetchHeadCellQuery({ param: "tsc-win_CM", user: "administrator" });
  const [updateInstance, fetching] = useUpdateInstanceTestMutation();
  const open = Boolean(anchorEl);
  const {
    data: countrylist,
    error: errorcountry,
    isFetching: fetchCountries,
  } = useFetchRobotCountriesQuery();
  const dummyData = data
    ? data?.data.map((item) => ({...item,
        id: item.id,
        INSTANCE: "1",

        dashboardreference:
          typeof item.dashboardreference === "string"
            ? item.dashboardreference
            : null,
        triggertype: getTriggerTypeFromNumber(item.triggertype), // You might need to adjust this based on your data
        verbose:
          item.verbose === "0"
            ? "None"
            : item.verbose == 1
            ? "Medium"
            : "Maximum",
        tasktype: item.tasktype == 2 ? "TEST" : null,
        device1area: item.device1area === "None" ? null : item.device1area,
        device1country:
          item.device1country == 0
            ? null
            : item.device1country === "9999"
            ? "Any"
            : countrylist?.data?.filter((x) => x.id == item.device1country)[0]
                ?.name,
        device1ratmode: getratmode(item.device1ratmode)?.join(","),
    
        device2area: item.device2area === "None" ? null : item.device2area,
        device2country:
          item.device2country == 0
            ? null
            : item.device2country === "9999"
            ? "Any"
            : countrylist?.data?.filter((x) => x.id == item.device2country)[0]
                ?.name,
        device2ratmode: getratmode(item.device2ratmode)?.join(","),
        device3area: item.device3area === "None" ? null : item.device3area,
        device3country:
          item.device3country == 0
            ? null
            : item.device3country === "9999"
            ? "Any"
            : countrylist?.data?.filter((x) => x.id == item.device3country)[0]
                ?.name,
        device3ratmode: getratmode(item.device3ratmode)?.join(","),
        matchedInstances: item.instance_data,
        ACTIONS: "", // You may need to determine how to derive the actions
      }))
    : [];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [selectedItems, setSelectedItems] = useState([]);
  const [instanceSelected, setInstanceSelected] = useState([]);
  const [newTest, setNewTest] = useState(false);
  const [modifyHeadCell] = useModifyHeadCellMutation();
  const toggleCheckbox = (id) => {
    setHeadCells((prevHeadCells) =>
      prevHeadCells.map((cell) =>
        cell.id === id ? { ...cell, isChecked: !cell.isChecked } : cell
      )
    );

    let tmpHeadCell = headCells.map((cell) =>
      cell.id === id ? { ...cell, isChecked: !cell.isChecked } : cell
    );
    modifyHeadCell({
      param: "tsc-win_CM",
      user: "administrator",
      data: {
        ...fetchheadCell?.data,
        columnHidden: {
          ...tmpHeadCell.reduce((result, item, index) => {
            result[item.id] = !item.isChecked;
            return result;
          }, {}),
        },
      },
    });
  };
  const [selectedTag, setSelectedTag] = useState("Anywhere");
  const filterSearchTable = () => {
    if (searchQuery.length > 2) {
      if (selectedTag == "Anywhere") {
        return dummyData.filter((item) => {
          return Object.values(item).some(
            (value) =>
              value &&
              value.toString().toLowerCase().includes(searchQuery.toLowerCase())
          );
        });
      }
      if (selectedTag == "Name") {
        return dummyData.filter((item) =>
          item.NAME?.toString()
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        );
      }
      if (selectedTag == "Test") {
        return dummyData.filter((item) =>
          item.TEST?.toString()
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        );
      }
      if (selectedTag == "Description") {
        return dummyData.filter((item) =>
          item.DESCRIPTION?.toString()
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        );
      }
    } else {
      return dummyData.reverse();
    }
  };
  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      dispatch(
        updateTestPayload({
          name: "signal",
          value: 1,
        })
      );
      // const message = "Changes you made may not be saved.";
      // event.returnValue = message;
      // return message;
      event.returnValue = true;
    };

    const handlePopstate = () => {
      dispatch(
        updateTestPayload({
          name: "signal",
          value: 1,
        })
      );
      const confirmed = window.confirm(
        "Are you sure you want to leave? Your changes may be lost."
      );
      if (confirmed) {
        // User confirmed leaving, you can perform additional actions here if needed
        // setFormDirty(false);
      } else {
        navigate(1);
        // Prevent leaving the page
        // navigate(1); // Go forward to revert the navigation change
      }
    };

    window.onpopstate = () => {};
    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);
  const payload = useSelector((state) => {
    return state.newTestPayload;
  });

  useEffect(() => {
    if (payload.name || payload.testid) {
      setNewTest(true);

      Swal.fire({
        title: "Data lost ?",
        text: "Do you want to continue? All input data will be lost.",
        width: "398px",
        showCancelButton: true,
        confirmButtonText: "Proceed",
        confirmButtonColor: "#AEC954",
        padding: "24px",
        customClass: {
          cancelButton:
            "h-[45px] px-10 !border-[1px] !border-solid !bg-transparent !rounded-3xl flex justify-center items-center !border-primary !text-primary",
          confirmButton: "!rounded-3xl px-10 h-[45px]",
          popup: "!text-[14px] !text-left",
          title: "!text-[20px] !p-0 !text-left !text-[#849C36]",
        },
        reverseButtons: true,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          dispatch(resetState());
          dispatch(resetStepState());
          setNewTest(false);
          // setValue(newValue);
        }
      });
    }
  }, []);
  const [loading, setloading] = useState(false);

  function hasMultipleDifferentSchIds(statusObjects) {
    const schIdSet = new Set();

    for (const statusObject of statusObjects) {
      const schId = statusObject.sch_id; // Assuming 'sch_id' is the key in your objects
      if (schId !== undefined) {
        schIdSet.add(schId);
      }
    }

    // Return false if there is more than one unique sch_id
    return schIdSet.size <= 1;
  }

  const handleUpdateInstance = (status) => {
    setloading(status);
    for (const i of instanceSelected) {
      updateInstance({ id: i.id, task_status: status }).then(() => {
        setloading(false);
      });
    }
    setInstanceSelected([]);
  };

  const [deleteThisTest] = useDeleteScheduleTestMutation();
  const deleteTest = () => {
    Swal.fire({
      title: "Deleting ?",
      text: "Do you want to delete this test?",
      width: "398px",
      showCancelButton: true,
      confirmButtonText: "Proceed",
      confirmButtonColor: "#AEC954",
      padding: "24px",
      customClass: {
        cancelButton:
          "h-[45px] px-10 !border-[1px] !border-solid !bg-transparent !rounded-3xl flex justify-center items-center !border-primary !text-primary",
        confirmButton: "!rounded-3xl px-10 h-[45px]",
        popup: "!text-[14px] !text-left",
        title: "!text-[20px] !p-0 !text-left !text-[#849C36]",
      },
      reverseButtons: true,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deleteThisTest(selectedItems.map((x) => parseInt(x))).then((result) => {
          if (result.data.status == true) {
            setSelectedItems([]);
            Swal.fire({
              title: "Deleted!",
              text: "Test Deleted Successfully",
              icon: "success",
              width: "398px",

              confirmButtonText: "Ok",
              confirmButtonColor: "#AEC954",
              padding: "24px",
              customClass: {
                confirmButton: "!rounded-3xl px-10 h-[45px]",
                popup: "!text-[14px] !text-left",
                title: "!text-[20px] !p-0 !text-left !text-[#849C36]",
              },
            });
          } else {
            Swal.fire({
              title: "Server Error",
              icon: "error",

              confirmButtonText: "OK",
              width: "398px",
              confirmButtonColor: "#AEC954",
              padding: "24px",
              customClass: {
                confirmButton: "!rounded-3xl px-10 h-[45px]",
                popup: "!text-[14px] !text-left",
                title: "!text-[20px] !p-0 !text-left !text-[#849C36]",
              },
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
            });
          }
        });
      }
    });
  };

  useEffect(() => {
    if (fetchheadCell?.data?.columnOrder && fetchheadCell?.data?.columnHidden) {
      setHeadCells(
        Object.entries(fetchheadCell.data.columnOrder).map((item) => {
          return {
            id: item[1],
            label: schedulerTableHeads.filter((x) => x.id == item[1])[0]?.label,
            isChecked:
              fetchheadCell.data.columnHidden[item[1]] == true ? false : true,
          };
        })
      );
    }
    else if(fetchheadCell?.data?.columnOrder)
    {
      setHeadCells(
        Object.entries(fetchheadCell.data.columnOrder).map((item) => {
          return {
            id: item[1],
            label: schedulerTableHeads.filter((x) => x.id == item[1])[0]?.label,
            isChecked:
              true
          };
        })
      );


    }
  }, [fetchheadCell]);

  if (newTest) return <NewTest setNewTest={setNewTest} />;
  return (
    <>
      <div className="px-10 mt-10">
        {/* header buttons */}
        <div className="flex items-center justify-between mb-5">
          <div
            onClick={() => setNewTest(true)}
            className="flex items-center justify-center gap-2 text-[14px] px-4 cursor-pointer py-2 text-[#AEC954] font-[600] border border-solid border-[#AEC954] rounded-3xl"
          >
            <PlusIcon />
            Create new task
          </div>
          {selectedItems.length > 0 ? (
            <div className="flex items-center gap-2">
              <div
                onClick={deleteTest}
                className="flex items-center justify-center gap-2 text-[14px] px-4 cursor-pointer py-2 text-[#DE4343] font-[600] border border-solid border-[#DE4343] rounded-3xl"
              >
                <DeleteIcon />
                Delete
              </div>
            </div>
          ) : instanceSelected &&
            instanceSelected.length > 0 &&
            hasMultipleDifferentSchIds(instanceSelected) ? (
            <div className="flex items-center gap-2">
              <ScheduleButton
                handleClick={() => handleUpdateInstance(1)}
                disabled={instanceSelected.some(
                  (obj) =>
                    obj.task_status == 1 ||
                    obj.task_status == 9 ||
                    obj.task_status == 4 ||
                    obj.task_status == 12 ||
                    obj.task_status == 3
                )}
                label="Run"
                loading={loading === 1}
              ></ScheduleButton>

              <ScheduleButton
                handleClick={() => handleUpdateInstance(13)}
                disabled={instanceSelected.some(
                  (obj) =>
                    obj.task_status == 9 ||
                    obj.task_status == 4 ||
                    obj.task_status == 3 ||
                    obj.task_status == 12
                )}
                label="Stop"
                loading={loading === 13}
              ></ScheduleButton>

              <ScheduleButton
                handleClick={() => handleUpdateInstance(0)}
                disabled={instanceSelected.some((obj) => obj.task_status == 1)}
                label="Reset"
                loading={loading === 0}
              ></ScheduleButton>

              <ScheduleButton
                handleClick={() => handleUpdateInstance(4)}
                disabled={instanceSelected.some(
                  (obj) => obj.task_status == 1 || obj.task_status == 4
                )}
                label="Disabled"
                loading={loading === 4}
              ></ScheduleButton>
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <TableSearch
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                setSelectedTag={setSelectedTag}
                selectedTag={selectedTag}
              />
              {/* <Tooltip title="Export" arrow>
                <div className="flex items-center cursor-pointer justify-center px-4 py-2 border border-solid border-[#E4E3E3] rounded-3xl">
                  <img className="w-[22px] h-[22px]" src={uploadIcon} />
                </div>
              </Tooltip>
              <Tooltip title="Import" arrow>
                <div className="flex items-center cursor-pointer justify-center px-4 py-2 border border-solid border-[#E4E3E3] rounded-3xl">
                  <img className="w-[22px] h-[22px]" src={downloadIcon} />
                </div>
              </Tooltip> */}
              <Tooltip title="Filter Columns" arrow>
                <div
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  className="flex items-center cursor-pointer justify-center px-4 py-2 border border-solid border-[#E4E3E3] rounded-3xl"
                >
                  <IoSettingsOutline fontSize={"22px"} color="#50514A" />
                </div>
              </Tooltip>
            </div>
          )}

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {headCells?.map(
              (item, index) =>
                item?.label && (
                  <MenuItem key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => {
                            toggleCheckbox(item.id);
                          }}
                          checked={item.isChecked == true}
                          sx={{
                            color: "#AEC954",
                            "&.Mui-checked": {
                              color: "#AEC954",
                            },
                          }}
                        />
                      }
                      label={item?.label}
                    />
                  </MenuItem>
                )
            )}
          </Menu>
        </div>
        {/* table */}

        <TestDataTable
          data={data}
          searchQuery={searchQuery}
          setHeadCells={setHeadCells}
          selectedItems={(items) => setSelectedItems(items)}
          instanceSelected={instanceSelected}
          setInstanceSelected={setInstanceSelected}
          headCells={headCells}
          name="schedular"
          orignalHeadCell={fetchheadCell?.data}
          dummyData={filterSearchTable()}
          loading={isFetching}
          selectCount={selectedItems}
          showCollapse={true}
          modifyparam="tsc-win_CM"
          draggable={true}
        />
      </div>
    </>
  );
};

export default Scheduler;
