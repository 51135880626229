import React from "react";
import { useSelector } from "react-redux";
import {
  StepDropDown,
  StepGreenHeading,
  StepInputField,
  StepTextArea,
} from "./Common";
import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import { Robot } from "./Step3";

const StepsOverview = () => {
  const payload = useSelector((state) => {
    return state.newTestPayload;
  });

  const { testnames, parameterslist } = useSelector((state) => {
    return state.newTestItems;
  });

  function AlphabeticallySortArray(arrayOfObjects) {
    let sortedarray = [...arrayOfObjects];
    return sortedarray.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // Ignore case while sorting
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return -1; // a should come before b in the sorted order
      }
      if (nameA > nameB) {
        return 1; // a should come after b in the sorted order
      }
      return 0; // names are equal, no change in order
    });
  }

  return (
    <>
      <StepGreenHeading label="Task" />
      <div className="flex flex-col md:flex-row gap-3 lg:gap-x-[136px]">
        <StepInputField
          label="Task Name"
          name="name"
          width="390px"
          value={payload?.name}
          //   handleChange={handleChange}
          disabled={true}
        />

        <StepInputField
          label="Test Name"
          name="name"
          width="390px"
          value={testnames?.find((x) => x.id == payload.testid).name}
          //   handleChange={handleChange}
          disabled={true}
        />
      </div>
      <StepTextArea
        disabled={true}
        label="Description"
        value={testnames
          ?.find((obj) => obj.id == payload.testid)
          ?.description.split("\\xA")
          .join("\n")
          .split("\\x2C")
          .join(",")}
      />
      <div className="flex flex-col md:flex-row gap-3 lg:gap-x-[136px]">
        <StepInputField
          label="Max test duration"
          width="390px"
          name="duration"
          //   handleChange={(e) => {
          //     e.target.value >= 0 && handleChange(e);
          //   }}
          value={payload?.duration}
          disabled={true}
        />

        {/* <StepDropDown
          label="Verbose mode"
          width="390px"
          data={[
            { id: 0, name: "None" },
            { id: 1, name: "Medium" },
            { id: 2, name: "Maximum" },
          ]}
          name="verbose"
        //   handleChange={handleChange}
          value={payload?.verbose}
        /> */}
      </div>

      <StepGreenHeading label="Test Parameter" />
      <div className="flex flex-col md:flex-row gap-3 lg:gap-x-[136px]">
        <div className="flex flex-col gap-y-2 flex-1">
          <div className="flex gap-x-[16px] items-end">
            <StepInputField
              label="Test Name"
              name="name"
              width="390px"
              value={
                [
                  { id: 0, name: "None" },
                  ...AlphabeticallySortArray(parameterslist),
                ]?.find((x) => x.id == payload.dataid).name
              }
              //   handleChange={handleChange}
              disabled={true}
            />

            {/* <BasicModal  data={data?data.status==true?data.data:{}:{}} id={payload.dataid}/> */}
          </div>
        </div>
      </div>

      <div className="flex  gap-x-6">
        <FormControlLabel
          sx={{ fontSize: "14px", fontWeight: 400, marginLeft: 0 }}
          control={
            <Checkbox
              checked={payload.taskflags == 8}
              //   onChange={handleEDBR}
              sx={{
                color: "#D6D6D6",
              }}
            />
          }
          labelPlacement="start"
          label="Edit Data before run"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={payload.restart == 1}
              //   onChange={handleReset}
              sx={{
                color: "#D6D6D6",
              }}
            />
          }
          labelPlacement="start"
          label="Reset Parameters index"
        />
      </div>

      <StepGreenHeading label="Selected Robots" />
      <Stack direction="column" rowGap={2} >
        {payload.RobotA && (
          <div className="flex flex-col gap-y-3">
            {" "}
            <Robot name="RobotA" disabled={true} />{" "}
            <StepInputField
              label="ICCID"
              width="390px"
              name="duration"
              value={payload?.Sim1?.iccid?.iccid}
              disabled={true}
            />
          </div>
        )}
  
        {payload.RobotB && (
          <div className="flex flex-col gap-y-3">
            {" "}
            <Robot name="RobotB" disabled={true} />{" "}
            <StepInputField
              label="ICCID"
              width="390px"
              name="duration"
              value={payload?.Sim2?.iccid?.iccid}
              disabled={true}
            />{" "}
          </div>
        )}

        {payload.RobotC && (
          <div className="flex flex-col gap-y-3">
            {" "}
            <Robot name="RobotC" disabled={true} />{" "}
            <StepInputField
              label="ICCID"
              width="390px"
              name="duration"
              value={payload?.Sim3?.iccid?.iccid}
              disabled={true}
            />
          </div>
        )}
      </Stack>
    </>
  );
};

export default StepsOverview;
